export const openCustomerPortal = async (userStripeId: string, userUID: string, id_token: string): Promise<any> => {
  try {
    const response = await fetch(`/.netlify/functions/customer-portal/open-customer-portal?userStripeId=${userStripeId}&userUID=${userUID}`, {
      method: 'POST',
      body: JSON.stringify({id_token: id_token}),
      headers: {
        'Content-Type': 'application/json'
      },
    });

    if (!response.ok) {
      console.log('Network response was not ok');
      return null;
    }

    const responsePromise = await response.json()
    return (responsePromise)
  } catch (error) {
    console.error('Internal Error', error);
    return null;
  }
};