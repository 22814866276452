import './styles.css'
import {Question} from "./Question/component";
import React, {useEffect, useRef, useState} from "react";
import {FrameType, Utils} from "../../utils/capture-utils";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../store";
import {CaptureStateStatus} from "../../../store/quizStore";
import {toSpeech} from "../../../services/textToSpeech";
import {Loader} from "../../../Common/components/Loader/component";
import {ScreenContainer} from "./styles";
import {Intro} from "./Intro/component";
import useAuthState from "../../../Common/hooks/auth-state-hook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons";
import {haveEnoughCreditsFor} from "../../../services/purchaseService";
import {PaidEvents} from "../../../types";
import {TopupCreditModal} from "../../../ChatApp/Components/TopupCreditsModal/component";

export interface QuizMetadata {
    quizRef: React.MutableRefObject<null>
}

export const ScreenApp: React.FC =
    observer(() => {

        const {currentUser} = useAuthState()
        const {quizStore} = useStores();
        const topupCreditModalState = useState(false);
        const [isUserAllowedLoading, setIsUserAllowedLoading] = useState(false);

        const utils = new Utils(quizStore);
        const {
            currentQuestionIndex, captureState, showAnswer, setShowAnswer,
            captureNext, initCapture, quiz
        } = quizStore

        const quizRef = useRef(null)

        const startRecording = async () => {
            if (!quizStore.preview) {
                // init intro with tts
                const introMetadata = await toSpeech({
                    text: quiz.intro.text,
                    languageCode: quizStore.voice?.languageCode,
                    voiceName: quizStore.voice?.voiceName,
                    ssmlGender: quizStore.voice?.gender
                })

                if (introMetadata) {
                    quizStore.updateQuiz({
                        intro: {
                            text: quizStore.quiz.intro?.text,
                            backgroundImg: quizStore.quiz.intro.backgroundImg,
                            audio: introMetadata.audioContent,
                            timepoints: introMetadata.timepoints,
                        }
                    })
                }

                // init questions with tts
                for (let i = 0; i < quiz.questions.length; i++) {
                    const question = quiz.questions[i]


                    const metadata = await toSpeech({
                        text: question.text,
                        languageCode: quizStore.voice?.languageCode,
                        voiceName: quizStore.voice?.voiceName,
                        ssmlGender: quizStore.voice?.gender
                    })

                    if (metadata) {
                        quizStore.updateQuizQuestion(i, {
                            audio: metadata.audioContent,
                            timepoints: metadata.timepoints,
                            audioDuration: metadata.audioDuration
                        })
                    }

                }
            }

            initCapture({
                quizRef: quizRef
            })

        }

        const preview = async () => {
            quizStore.setPreview(true)
            quizStore.videoPreviewURL = ''
            startRecording()
        }

        const recordAndDownload = async () => {
            if (currentUser) {
                setIsUserAllowedLoading(true)
                var allowed = await haveEnoughCreditsFor(PaidEvents.DOWNLOAD_QUIZ_VIDEO, currentUser.uid)
                console.log("allowed", allowed)
                setIsUserAllowedLoading(false)
                if (!allowed) {
                    topupCreditModalState[1](true)
                    return;
                }
            }
            quizStore.setPreview(false)
            quizStore.videoPreviewURL = ''
            quizStore.startDownloading()

            startRecording()
        }

        const handlePreviewTimeChange = (event: any) => {
            quizStore.currentQuestionIndex = Number(event.target.value)
        }

        // Capture answer
        useEffect(() => {
                if (captureState.getStatus() === CaptureStateStatus.ANSWER) {
                    if (showAnswer) {
                        utils.captureQuizFrame(FrameType.ANSWER, 1000).then(() => {
                            // Wait 1 second while capturing answer and start encoding
                            if (!quizStore.preview && (currentQuestionIndex + 1 >= quiz.questions.length)) {
                                utils.convertFramesToVideo(30, currentUser?.uid)
                            }
                            captureNext()
                        })
                    } else {
                        setShowAnswer(true)
                    }
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
            , [showAnswer, captureState.status, captureState, setShowAnswer, captureNext, currentQuestionIndex])

        return <>
            <TopupCreditModal topupCreditModaleState={topupCreditModalState}
                              userId={currentUser?.uid}></TopupCreditModal>
            <div className={"box-shadow"}>

                <div className="d-grid gap-2 my-3">
                    <button className="btn btn-primary"
                            type="button"
                            disabled={quizStore.isDownloading()}
                            onClick={preview}>preview
                    </button>
                    <button
                        className="btn btn-primary"
                        disabled={quizStore.isDownloading() || isUserAllowedLoading}
                        type="button"
                        onClick={recordAndDownload}>Get video
                        <span className={"small"}> (10 <FontAwesomeIcon size={"xs"} icon={faCoins}/>)</span>
                    </button>
                </div>

                <div className={"loader-and-screen-container"}>
                    {quizStore.isDownloading() && (
                        <div className={"loader"}>
                            <div className="d-flex flex-column align-items-center">
                                <Loader></Loader>
                            </div>
                        </div>
                    )}
                    {
                        quizStore.videoPreviewURL ? (<div className={"video-container"}>
                            <video height={"500px"} width={"281px"} controls>
                                <source src={quizStore.videoPreviewURL} type={"video/mp4"}/>
                                Your browser does not support the video tag.
                            </video>
                        </div>) : (
                            <>
                                <ScreenContainer ref={quizRef} $blur={quizStore.isDownloading()}
                                                 $backgroundImg={currentQuestionIndex === -1 ? quiz.intro?.backgroundImg : quiz.questions[currentQuestionIndex]?.backgroundImg}>
                                    {
                                        currentQuestionIndex === -1 && (<>
                                            <div className={"intro-container"}>
                                                <Intro intro={quizStore.quiz.intro}></Intro>
                                            </div>
                                        </>)
                                    }
                                    {currentQuestionIndex >= 0 && (
                                        <Question question={quiz.questions[currentQuestionIndex]}
                                        ></Question>
                                    )}
                                </ScreenContainer>
                                <div className={"preview-range-container"}>
                                    <label htmlFor="currentQuestion" className="form-label"></label>
                                    <input type="range"
                                           disabled={quizStore.isDownloading()}
                                           className="preview-range"
                                           width={50}
                                           min={-1}
                                           max={quizStore.quiz.questions.length - 1}
                                           id="currentQuestion"
                                           value={currentQuestionIndex}
                                           onChange={(event) => handlePreviewTimeChange(event)}/>
                                </div>
                            </>

                        )
                    }

                </div>
            </div>
        </>

    })