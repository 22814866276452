import './styles.css'
import {observer} from "mobx-react-lite";
import {Simulate} from "react-dom/test-utils";
import progress = Simulate.progress;
import {useStores} from "../../../store";

export const Loader: React.FC =
    observer(() => {

      const {quizStore} = useStores()
      const {currentQuestionIndex, totalQuestions, renderProgress} = quizStore

      const questionNumber = currentQuestionIndex === -1 ? 'Intro' : `${currentQuestionIndex + 1}/${totalQuestions}`;

      const getDisplayMessage = () => {
        if (quizStore.isCapturing()) {
          return `Capturing ${questionNumber}`
        } else if (quizStore.isRendering()) {
          return `${renderProgress}%`
        }
        return 'Loading ...'
      }

      return <div className={"loader-container"}>
        <img className={"sand-timer"} width={"40px"} height={"35px"}
             src={require('../../../assets/timer.png')} alt={'timer'}/>
        {(progress !== undefined) && (
            <div className={"loader-progress"}>
              {getDisplayMessage()}
            </div>
        )}
      </div>
    })