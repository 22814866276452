export const topupCredits = async (uid: string, credits: number): Promise<any> => {
    try {
        const priceId = process.env.REACT_APP_PUBLIC_STRIPE_TOPUP_PRODUCT_ID
        const response = await fetch(`/.netlify/functions/topup-credits/topup-credits?uid=${uid}&credits=${credits}&priceId=${priceId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "reload"
        });
        if (!response.ok) {
            console.log('Network response was not ok');
            return null;
        }

    const responsePromise = await response.json()
    return (responsePromise)
  } catch (error) {
    console.error('Internal Error', error);
    return null;
  }
};