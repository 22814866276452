import {toTtsReponseWithDurations} from "../mappers/tts-audio/gc-tts-mapper";
// @ts-ignore
import {google} from "@google-cloud/text-to-speech/build/protos/protos";

interface VoiceOverParams {
  text: string;
  languageCode?: (string | null);
  voiceName?: (string | null);
  ssmlGender?: (google.cloud.texttospeech.v1beta1.SsmlVoiceGender | keyof typeof google.cloud.texttospeech.v1beta1.SsmlVoiceGender | null);

}

export const toSpeech = async (params: VoiceOverParams): Promise<any> => {
  try {
    const response = await fetch('/.netlify/functions/gc-tts/gc-tts', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json'
      },
    });

    if (!response.ok) {
      console.log('Network response was not ok');
      return null;
    }

    return toTtsReponseWithDurations(await response.json())
  } catch (error) {
    console.error('Internal Error', error);
    return null;
  }
};