// Routes.tsx
import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {QuizApp} from "./QuizApp/Components/app/component";
import ChatApp from "./ChatApp/Components/ChatApp/component";
import {MyProfile} from "./Common/components/MyProfile/component";
import {LandingPage} from "./Common/components/landing/landing-page";
import FinishSignIn from "./Common/components/CompleteSignIn/component";
import {AuthPage} from "./Common/components/AuthPage/auth-page";

export const RoutesApp: React.FC = () => {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage/>}/>
          <Route path="/finish-signin" element={<FinishSignIn/>}/>
          <Route path="/texting-story" element={<ChatApp/>}/>
          <Route path="/login" element={<AuthPage/>}/>
          <Route path="/quiz" element={<QuizApp/>}/>
          <Route path="/profile" element={<MyProfile/>}/>
          <Route path="*" element={<div>not found</div>}/>
        </Routes>
      </BrowserRouter>
  );
};

export default Routes;