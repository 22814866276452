import firebase from 'firebase/compat/app';

import {createUserWithEmailAndPassword, isSignInWithEmailLink, signInWithEmailAndPassword} from "firebase/auth";
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import {firebaseAuth} from "../config/firebase-config";
import {topupNewUserCredits} from "../services/topupNewUserCredits";
import {sendEmailVerification, sendSignInLinkToEmail} from "@firebase/auth";
import {absoluteUrl} from "../utils/utils";
import {logToNetlify} from "../logging/logging";

const googleProvider = new GoogleAuthProvider();

export const registerUser = async (email: string, password: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            const userCredentials = await createUserWithEmailAndPassword(firebaseAuth, email, password)
            sendEmailVerification(userCredentials.user)

            if (userCredentials.user.uid) {
                await topupNewUserCredits(userCredentials.user.uid)
            }
            resolve(userCredentials)
        } catch (error: any) {
            console.log(error.message)
            reject(error)
        }
    })
};

export const loginUser = async (email: string, password: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const userCredentials = await signInWithEmailAndPassword(firebaseAuth, email, password)
            resolve(userCredentials)
        } catch (error: any) {
            console.log(error.message)
            reject(error)
        }
    })
};

export const signInWithGoogle = async () => {
    try {
        const result = await firebaseAuth.signInWithPopup(googleProvider);
        const isNewUser = result.additionalUserInfo?.isNewUser
        const user = result.user;
        if (isNewUser && user?.uid) {
            await topupNewUserCredits(user.uid)
        }
        return user;
    } catch (error) {
        console.error("Error during Google sign-in:", error);
        throw error; // Rethrow the error for handling in the component
    }
};

export const sendLoginMagicLink = async (email: string) => {

    return new Promise(async (resolve, reject) => {
        try {
            const userCredentials = await sendSignInLinkToEmail(firebaseAuth, email, {
                    url: `${absoluteUrl("/finish-signin")}`,
                    handleCodeInApp: true
                }
            )
            window.localStorage.setItem('emailForSignIn', email);
            resolve(userCredentials)
        } catch (error: any) {
            console.log(error.message)
            reject(error)
        }
    })
}

export const loginWithEmailLink = async () => {
    return new Promise(async (resolve, reject) => {
        if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                reject({reason: "MISSING_EMAIL"})
                return;
            }
            // The client SDK will parse the code from the link for you.
            try {
                const result = await firebaseAuth.signInWithEmailLink(email!, window.location.href)

                // Clear email from storage.
                window.localStorage.removeItem('emailForSignIn');
                // You can access the new user via result.user
                // Additional user info profile not available via:
                // result.additionalUserInfo.profile == null
                // You can check if the user is new or existing:
                // result.additionalUserInfo.isNewUser
                if (result.additionalUserInfo?.isNewUser && result.user?.uid) {
                    await topupNewUserCredits(result.user?.uid)
                }
                resolve(result)
                console.log("addInfo", result)
            } catch (error) {
                reject(error)
            }
        } else {
            reject({reason: "NOT_A_SINGIN_LINK"})
        }
    })
}

export const isDisposable = async (email: string) => {
    try {
        const content = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/disposable_email_blocklist.conf`)
        const contentText = await content.text()
        const blocklist = contentText.split(/\r\n|\n|\r/).slice(0, -1)
        return blocklist.includes(email.split('@')[1])
    } catch (error) {
        logToNetlify("[Error from isDisposable]", error, email)
        return false
    }
};