import {useEffect, useState} from "react";
import {getUserSession} from "../session/session";
import {useNavigate} from "react-router-dom";

interface ProtectedProps {
  children: React.ReactNode;
}

export const Protected: React.FC<ProtectedProps> = ({children}) => {

  const [user, setUser] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    getUserSession().then(user => {
      setUser(user)
    }).catch(() => {
      navigate('/login')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    {user && (children)}
  </>
}