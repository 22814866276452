import './styles.css'
import '../../../index.css'

export const GreetingComponent = () => {
  return <div className={"greeting-container"}>
    <div className={"greeting-title"}>
      <h2>Unleash your creativity !</h2>
      <p>Start creating your stories 👇👇</p>
    </div>
    <div className={"steps-container"}>
      <div className={"logo-step"}>
        <img src={require("../../img/bubble.png")} alt="bubble" width={70} height={70}/>
        <span>Write</span>
      </div>
      <div className={"logo-step"}>
        <img src={require("../../img/download1.png")} alt="bubble" width={70} height={70}/>
        <span>Export</span>
      </div>
      <div className={"logo-step"}>
        <img src={require("../../img/share1.png")} alt="bubble" width={70} height={70}/>
        <span>Share</span>
      </div>
    </div>
    <div className="greeting-title">
      <div className="btn btn-link">
        <a href="https://insigh.to/b/convoclip" target="_blank" rel="noreferrer" >
          Suggest a feature/ report a problem
        </a>
      </div>
    </div>
  </div>
}