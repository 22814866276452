import {QuizForm} from "../form/component";
import {ScreenApp} from "../Screen/component";

export const MainComponent: React.FC = () => {
  return <>
    <div className="row">
      <div className="col-md-8 mb-5 mb-md-5">
        <QuizForm></QuizForm>
      </div>
      <div className="col-md-4">
        <ScreenApp></ScreenApp>
      </div>
    </div>
  </>
}