import {CustomSignIn} from "../../../auth/SignIn/signin";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getUserSession} from "../../session/session";

export const AuthPage = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState()

  useEffect(() => {
    getUserSession().then(user => {
      setCurrentUser(user)
      navigate('/')
    }).catch(error => {
      console.log("no user signed in")
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    {!currentUser && (<div
        className={"d-flex align-items-center justify-content-center align-content-center vh-100"}>
      <div className={"col col-lg-6 col-md-8"}>
        <CustomSignIn/>
      </div>
    </div>)}
  </>
}
