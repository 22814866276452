import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {ReactState} from "../../../ChatApp/utils/types/types";
import {pricingData} from "../../../config/subscriptions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import useUserSubscriptionState from "../../hooks/user-subscription-hook";
import {SubscriptionPlan} from "../../../types";
import {redirect} from "react-router-dom";
import {generateUserStripe} from "../../../actions/generate-user-stripe";

interface BillingFormButtonProps {
    offer: SubscriptionPlan;
    year: boolean;
}

const BillingFormButton: React.FC<BillingFormButtonProps> =
    ({
         year,
         offer
     }) => {
        const {currentUser, userSubscription, subscriptionLoading} = useUserSubscriptionState()
        const [isPending, setIsPending] = useState(false)

        const generateUserStripeSession = async () => {
            setIsPending(true)
            const offerStripeId = offer.stripeIds[year ? "yearly" : "monthly"]

            if (!currentUser || !offerStripeId) return;

            generateUserStripe(currentUser.uid, offerStripeId).then(paymentUrl => {
                    window.open(paymentUrl, '_blank', 'noopener,noreferrer');
                }
            ).catch(error => {
                // todo show toaster :(
            }).finally(() => setIsPending(false))
        }


        const freePlan = offer.title.toLowerCase() === "starter"
        const paidPlan = offer.title.toLowerCase() === "pro" || offer.title.toLowerCase() === "business"

        const userOffer =
            userSubscription?.stripePriceId ===
            offer.stripeIds[year ? "yearly" : "monthly"];

        const handleButtonClick = () => {
            if (!currentUser) {
                redirect("/login")
                return
            }

            if (freePlan) {
                // go to dashboard
                redirect("/")
            }

            if (paidPlan) {
                // start stripe session
                generateUserStripeSession()
            }
        }

        const getDisplayText = () => {
            if (!currentUser) {
                return "Sign in"
            }

            if (freePlan) {
                return "Go to dashboard"
            }

            if (userOffer) {
                return "Manage subscription"
            }

            return "Upgrade"
        }

        return <>
            <button className="btn btn-outline-secondary rounded-5"
                    disabled={subscriptionLoading || isPending}
                    onClick={() => handleButtonClick()}>
                {isPending ? (
                    <div className={"spinner-border spinner-border-sm"}>
                        <span className={"sr-only"}></span>
                    </div>
                ): <>
                    {getDisplayText()}</>}
            </button>
        </>
    }

export const Pricing: React.FC<{
    pricingModalState: ReactState<boolean>,
}> = ({pricingModalState}) => {

    const [isYearly, setIsYearly] = useState(false)

    const closeModal = () => {
        pricingModalState[1](false)
    }

    return <Modal
        show={pricingModalState[0]}
        size={"xl"}
        onHide={closeModal}
        aria-labelledby="Authentication Modal"
        aria-describedby="Authenticatin Modal"
    >
        <ModalHeader>
            <div className={"d-flex flex-column align-items-center w-100"}>
                <div>
                    Pricing
                </div>
                <h2 className={"fw-bold mt-3"}>Start at full speed !</h2>
                <div className="d-flex align-items-center">
                    <label htmlFor="flexSwitchCheckDefault" className="me-2">Monthly</label>
                    <div className="form-check form-switch">
                        <input className="form-check-input"
                               style={{width: '70px', height: '30px'}}
                               checked={isYearly}
                               onChange={(event) => {
                                   setIsYearly(event.target.checked)
                               }}
                               type="checkbox" id="flexSwitchCheckDefault"/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                    </div>
                    <label htmlFor="flexSwitchCheckDefault" className="ms-2">Yearly (-30%)</label>
                </div>
            </div>
        </ModalHeader>
        <ModalBody>
            <div className={"h-100 d-flex row justify-content-around column-gap-2 row-gap-4"}>
                {pricingData.map(plan => (
                    <div className={"col-lg col-md-12"} key={plan.title}>
                        <div className={"h-100 border rounded-5"}>
                            <div className={"h-100 d-flex flex-column justify-content-between"}>
                                <div
                                    className={"rounded-top-5 p-4 bg-light d-flex flex-column justify-content-center"}>
                                    <div className={"text-uppercase fw-semibold text-muted"}>{plan.title}</div>
                                    {/**/}
                                    <div className="d-flex flex-row">
                                        <div className="d-flex justify-content-end align-items-baseline">
                                            <div className="d-flex fs-1 fw-semibold">
                                                {isYearly && plan.prices.monthly > 0 ? (
                                                    <>
                        <span className="me-2 text-muted text-decoration-line-through">
                        ${plan.prices.monthly}
                        </span>
                                                        <span>${plan.prices.yearly / 12}</span>
                                                    </>
                                                ) : (
                                                    `$${plan.prices.monthly}`
                                                )}
                                            </div>
                                            <div
                                                className="ms-2 text-muted">
                                                <div>/month</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  */}

                                </div>
                                <div className={"mt-3"}>
                                    {plan.benefits.map((benefit) => (
                                        <div className={"ms-2 mb-2 fw-semibold"} key={benefit}>
                                            <FontAwesomeIcon icon={faCheck}
                                                             className={"text-success me-4"}></FontAwesomeIcon>{benefit}
                                        </div>
                                    ))}
                                    <br/>
                                    {plan.limitations.map(limitation => (
                                        <div className={"ms-2 mb-2 fw-light text-muted"} key={limitation}>
                                            <FontAwesomeIcon icon={faXmark}
                                                             className={"text-secondary me-4"}></FontAwesomeIcon>{limitation}
                                        </div>
                                    ))}</div>

                                <div className={"d-grid p-3"}>
                                    <BillingFormButton offer={plan}
                                                       year={isYearly}/>
                                </div>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </ModalBody>
    </Modal>
}