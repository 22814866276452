import firebase from 'firebase/compat/app';
import "firebase/compat/firestore";
import {db} from "../config/firebase-config";
import {DbUserSubscriptionPlan} from "../types";

export interface Subscription {
  plan: "starter" | "premium";
  startDate?: firebase.firestore.Timestamp;
  endDate?: firebase.firestore.Timestamp | null;
  status: "active" | "canceled";
  amount: number;
}

export const subscribeUserLegacy = async (user: any, plan: "starter" | "premium", amount: number) => {

  if (!user) throw new Error("User not authenticated");

  const userRef = db.collection('users').doc(user.uid);
  const subscriptionRef = userRef.collection('subscriptions').doc('current');

  const subscriptionData: Subscription = {
    plan,
    startDate: firebase.firestore.Timestamp.now(),
    endDate: null, // Active subscription has no end date
    status: "active",
    amount,
  };

  await subscriptionRef.set(subscriptionData);
};

export const cancelSubscriptionLegacy = async (user: any) => {
  if (!user) throw new Error("User not authenticated");

  const userRef = db.collection('users').doc(user.uid);
  const subscriptionRef = userRef.collection('subscription').doc('current');

  const subscriptionDoc = await subscriptionRef.get();
  if (!subscriptionDoc.exists) throw new Error("No active subscription found");

  await subscriptionRef.update({
    status: "canceled",
    endDate: firebase.firestore.Timestamp.now(),
  });
};

export const getUserSubscriptionLegacy = async (user: any): Promise<Subscription | null> => {
  const subscriptionRef = firebase.firestore()
  .collection('users')
  .doc(user.uid)
  .collection('subscription')
  .doc('current');

  const subscriptionDoc = await subscriptionRef.get();

  if (subscriptionDoc.exists) {
    return subscriptionDoc.data() as Subscription;
  } else {
    return null; // No active subscription found
  }
};

export const getUserSubscription = async (uid: string): Promise<DbUserSubscriptionPlan> => {
  console.log("uid", uid)
  const subscriptionRef = db
  .collection('users')
  .doc(uid)
  .collection('subscriptions')
  .doc('current');

  const subscriptionDoc = await subscriptionRef.get();

  if (subscriptionDoc.exists) {
    return subscriptionDoc.data() as DbUserSubscriptionPlan;
  } else {
    return {
      stripeCurrentPeriodEnd: undefined,
      stripeCustomerId: "",
      stripePriceId: "",
      stripeSubscriptionId: "",
      credits: 0
    }; // No active subscription found
  }
};