import styled, {css} from "styled-components";
import backgroundImg from "../../assets/img_1.png"

export const ScreenContainer = styled.div<{
  $blur: boolean
  $backgroundImg: string | undefined
}>`${props =>
    css`
      height: 500px;
      min-width: 281px;
      max-width: 281px;
      border-radius: 7px;
      border: 2px solid;
      margin: auto;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background-image: url(${props.$backgroundImg ?? backgroundImg});
      background-size: 100% 100%;
      filter: ${props.$blur ? 'blur(5px)' : ''};
    `
}
`