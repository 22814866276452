import HeaderComponent from "../Header/components";
import useAuthState from "../../hooks/auth-state-hook";
import {Protected} from "../../layout/protected";
import useUserSubscriptionState from "../../hooks/user-subscription-hook";
import {openCustomerPortal} from "../../../actions/open-customer-portal";
import {Pricing} from "../Pricing/component";
import React, {useState} from "react";
import {topupCredits} from "../../../actions/topup-credits";

export const MyProfile: React.FC = () => {
    const {currentUser, idToken} = useAuthState();
    const {userSubscription} = useUserSubscriptionState()
    const pricingModalState = useState(false)
    const [topupAmount, setTopupAmount] = useState(300)

    const creditPrice = 0.03
    // Loaders
    const [buyCreditsLoader, setBuyCreditsLoader] = useState(false)
    const [subscriptionLoader, setSubscriptionLoader] = useState(false)

    // Function to format Firestore timestamp to local date
    const formatDate = (timestamp: { seconds: number; nanoseconds: number }) => {
        const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
        return date.toLocaleDateString(); // Format the date as needed
    };

    const {
        isPaid,
        isCanceled,
        title,
        stripeCurrentPeriodEnd,
        stripeCustomerId,
        credits
    } = userSubscription ?? {}

    const handleOpenCustomerPortal = () => {
        if (currentUser && idToken && stripeCustomerId) {
            setSubscriptionLoader(true)
            openCustomerPortal(stripeCustomerId, currentUser.uid, idToken).then(customerPortalUrl => {
                window.open(customerPortalUrl, '_blank', 'noopener,noreferrer');
                setSubscriptionLoader(false)
            })
        }
    }

    const topupCreditsUrl = () => {
        if (currentUser) {
            setBuyCreditsLoader(true)
            topupCredits(currentUser.uid!, topupAmount).then(topupUrl => {
                window.open(topupUrl, '_blank', 'noopener,noreferrer');
                setBuyCreditsLoader(false)
            })
        }
    }

    const handleTopupAmountChange = (value: number) => {
        setTopupAmount(value - (value % 100))
    }

    return (
        <>
            <HeaderComponent/>
            <Protected>
                <Pricing pricingModalState={pricingModalState}></Pricing>
                <div className={"container mt-5"}>
                    <div className={"d-flex flex-column row-gap-4 col-md-8"}>
                        <div><h1>Settings</h1>
                            <p>Manage account and billing settings</p>
                        </div>
                        <div className={"card shadow-sm p-3"}>
                            <h2>Email</h2>
                            <p>{currentUser?.email}</p>
                        </div>

                        <div className={"card shadow-sm"}>
                            <div className={"p-3"}>
                                <h3>Subscription plan</h3>
                                <p>You are currently on the <strong>{title}</strong> plan.</p>
                            </div>
                            <div className={"card-footer"}>
                                <div className={"d-flex justify-content-between  align-items-center"}>
                                    {isPaid ? (
                                        <div>
                          <span className="text-sm font-medium text-muted-foreground">
                            {isCanceled
                                ? (<span>Your plan will be <strong>canceled</strong> on </span>)
                                : "Your plan renews on "}
                              {formatDate(stripeCurrentPeriodEnd!)}.
                          </span>
                                        </div>
                                    ) : null}
                                    {isPaid && stripeCustomerId ? (
                                        <button className={"col-4 btn btn-outline-danger"}
                                                disabled={subscriptionLoader}
                                                onClick={handleOpenCustomerPortal}
                                        >{subscriptionLoader ? (
                                            <div className={"spinner-border-sm spinner-border"}>
                                                <span className="sr-only"></span>
                                            </div>
                                        ) : <>
                                            Manage subscription
                                        </>
                                        }
                                        </button>
                                    ) : (
                                        <button className={"btn btn-outline-danger"}
                                                onClick={() => pricingModalState[1](true)}>Choose a plan</button>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={"card shadow-sm mb-4"}>
                            <div className={"p-3"}>
                                <h3>Available credits</h3>
                                <p>You have <strong>{credits ?? 0}</strong> credits available.</p>
                                <div className={"row align-items-center"}>
                                    <div className={"col-8"}>
                                        <label htmlFor="typingSpeed" className="form-label">
                                            Topup <strong>{topupAmount} </strong>credits for <strong>{creditPrice*topupAmount}$</strong>
                                        </label>
                                        <input type="range"
                                               className="form-range"
                                               min={300}
                                               max={10000}
                                               id="typingSpeed"
                                               value={topupAmount}
                                               onChange={(event) => handleTopupAmountChange(Number(event.target.value))}/>
                                    </div>
                                    <button className={"col-4 btn btn-outline-danger"}
                                            disabled={buyCreditsLoader}
                                            onClick={topupCreditsUrl}
                                    >
                                        {buyCreditsLoader ? (
                                                <div className={"spinner-border-sm spinner-border"}>
                                                    <span className="sr-only"></span>
                                                </div>) :
                                            <>
                                                Buy {topupAmount} credits
                                            </>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Protected>
        </>
    )
        ;
};