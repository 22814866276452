import {firebaseStorage} from "../config/firebase-config";

export interface VideoLink {
  name: string;
  downloadUrl: string;
}

export const uploadVideo = async (videoFile: File, userId: string): Promise<void> => {
  if (!videoFile) {
    throw new Error("Unsupported videoFile");
  }

  const storageRef = firebaseStorage.ref(`${userId}/quiz/${videoFile.name}`);

  try {
    // Upload the video
    await storageRef.put(videoFile);
  } catch (uploadError) {
    throw new Error("Error uploading video: " + uploadError);
  }
};

export const getUserVideos = async (userId: string): Promise<VideoLink[] | null> => {
  const listRef = firebaseStorage.ref(`${userId}/quiz`);

  try {
    const videoUrls: VideoLink[] = [];
    const res = await listRef.listAll();

    for (const itemRef of res.items) {
      const url = await itemRef.getDownloadURL();
      videoUrls.push({
        name: itemRef.name,
        downloadUrl: url
      });
    }

    return videoUrls;
  } catch (error) {
    console.error("Error listing files:", error);
    return null;
  }
};

export const getVideoUrl = async (url: string): Promise<string | null> => {
  const fileRef = firebaseStorage.ref(url);

  try {
    const downloadUrl = await fileRef.getDownloadURL();
    console.log('Download URL:', downloadUrl);
    return downloadUrl;
  } catch (error) {
    console.error('Error getting download URL:', error);
    return null;
  }
};
