import {makeAutoObservable} from "mobx";
import {QuizMetadata} from "../QuizApp/Components/Screen/component";
import {VideoFrame} from "../QuizApp/utils/capture-utils";
import React from "react";
import {Question, Quiz, quizInit} from "../QuizApp/utils/quiz-form";
import {Voice, voicesMap} from "../QuizApp/Sounds/voices/voicesMap";

export enum CaptureStateStatus {
  QUIZ_INTRO,
  QUESTION_TITLE,
  TIMER,
  ANSWER,
  IDLE
}

class CaptureState {
  status: CaptureStateStatus;

  constructor() {
    this.status = CaptureStateStatus.IDLE;
    makeAutoObservable(this)
  }

  getStatus = (): CaptureStateStatus => {
    return this.status;
  }

  setStatus = (status: CaptureStateStatus) => {
    this.status = status
  }

  public next = (questionIndex: number, totalQuestions: number) => {
    switch (this.status) {
      case CaptureStateStatus.IDLE:
        this.status = CaptureStateStatus.QUIZ_INTRO;
        break;
      case CaptureStateStatus.QUIZ_INTRO:
        this.status = CaptureStateStatus.QUESTION_TITLE;
        break;
      case CaptureStateStatus.QUESTION_TITLE:
        this.status = CaptureStateStatus.TIMER;
        break;
      case CaptureStateStatus.TIMER:
        this.status = CaptureStateStatus.ANSWER;
        break;
      case CaptureStateStatus.ANSWER: {
        if (questionIndex === totalQuestions) {
          this.status = CaptureStateStatus.IDLE;
          break;
        } else {
          this.status = CaptureStateStatus.QUESTION_TITLE;
          break;
        }
      }
    }
  }
}

export class QuizStore {
  // Quiz
  quiz: Quiz = quizInit;
  videoFrames: VideoFrame[] = [];
  totalQuestions = 3;
  quizRef: React.MutableRefObject<null> | undefined
  videoPreviewURL: string = ''

  // Variable
  currentQuestionIndex = -1;
  showAnswer = false;
  frameIndex = 0;
  timer = -1;
  captureState: CaptureState = new CaptureState();
  renderProgress = 0;
  downloading = false;
  preview = false;

  // Quiz parameters
  voice: Voice = voicesMap["english"][0]
  language = "english"


  constructor() {
    makeAutoObservable(this);
  }

  getCurrentQuestionIndex = () => {
    return this.currentQuestionIndex;
  }

  setTotalQuestions = (number: number) => {
    this.totalQuestions = number;
  }

  captureNext = () => {
    if (this.captureState.getStatus() === CaptureStateStatus.QUIZ_INTRO) {
      this.currentQuestionIndex++;
    }
    if (this.currentQuestionIndex + 1 < this.totalQuestions
        && this.captureState.getStatus() === CaptureStateStatus.ANSWER) {
      this.currentQuestionIndex++;
      this.showAnswer = false;
      this.timer = -1;
      this.captureState.next(this.currentQuestionIndex, this.totalQuestions);
    } else {
      this.captureState.next(this.currentQuestionIndex + 1, this.totalQuestions);
    }
  }

  setTimer = (time: number) => {
    this.timer = time
  }

  setShowAnswer = (show: boolean) => {
    this.showAnswer = show;
  }

  initCapture = (metadata?: QuizMetadata) => {
    if (metadata) {
      this.quizRef = metadata.quizRef;
    }
    this.totalQuestions = this.quiz.questions.length;
    this.videoFrames = []
    this.currentQuestionIndex = -1
    this.renderProgress = 0
    this.timer = -1
    this.showAnswer = false
    this.captureState.setStatus(CaptureStateStatus.QUIZ_INTRO)
  }

  upgetFrameIndex = (): number => {
    this.frameIndex++;
    return this.frameIndex
  }

  pushFrame = (frame: VideoFrame) => {
    this.videoFrames.push(frame)
  }

  getQuestionDuration = (questionIndex: number) => {
    return this.quiz.questions[questionIndex].audioDuration
  }

  getIntroDuration = () => {

  }

  updateQuizQuestion(questionIndex: number, overrides?: Partial<Question>) {
    this.quiz.questions[questionIndex] = {
      ...this.quiz.questions[questionIndex],
      ...overrides
    }
  }

  updateQuizOption(questionIndex: number, optionId: number, optionValue: string) {
    const index = this.quiz.questions[questionIndex].options.findIndex(option => option.id === optionId)
    if (index > -1) {
      this.quiz.questions[questionIndex].options[index].value = optionValue
    }
  }

  removeQuizOption(questionIndex: number, optionId: number) {
    const index = this.quiz.questions[questionIndex].options.findIndex(option => option.id === optionId)
    if (index > -1) {
      this.quiz.questions[questionIndex].options.splice(index, 1)
    }
  }

  addQuizOption(questionIndex: number) {
    this.quiz.questions[questionIndex].options = [
      ...this.quiz.questions[questionIndex].options,
      {
        correct: false,
        value: "",
        id: this.quiz.questions[questionIndex].options.length + 1
      }
    ]
  }

  setSelectedVoice(voice: Voice) {
    this.voice = voice
  }

  setLanguage(language: string) {
    this.language = language
  }

  setCorrectAnswer(questionIndex: number, optionId: number) {
    const index = this.quiz.questions[questionIndex].options.findIndex(option => option.id === optionId)
    if (index > -1) {
      this.quiz.questions[questionIndex].options.forEach(option => {
        option.correct = option.id === optionId
      })
    }
  }

  updateQuiz(overrides: Partial<Quiz>) {
    this.quiz = {
      ...this.quiz,
      ...overrides
    }
  }

  setQuiz(quiz: Quiz) {
    this.quiz = quiz
    this.totalQuestions = this.quiz.questions.length
  }

  isCapturing(): boolean {
    return this.downloading && (this.captureState.getStatus() !== CaptureStateStatus.IDLE)
  }

  isRendering(): boolean {
    return this.downloading && (this.renderProgress > 0 && this.renderProgress < 100);
  }

  isDownloading(): boolean {
    return this.downloading;
  }

  setRenderProgress(value: number) {
    this.renderProgress = value;
  }

  setPreview(value: boolean) {
    this.preview = value
  }

  updateQuestionImages(blobUrls: any[]) {
    this.quiz.intro.backgroundImg = blobUrls[0]
    this.quiz.questions = this.quiz.questions.map((question, index) => {
      return {
        ...question,
        backgroundImg: blobUrls[index+1]
      }
    })
  }

  startDownloading() {
    this.downloading = true;
  }

  finishDownloading() {
    this.downloading = false;
  }

  totalNbQuestions() {
    return this.quiz.questions.length;
  }

  addNewQuestion() {
    if (this.totalNbQuestions() > 7) return;
    this.totalQuestions++;
    this.quiz.questions.unshift({
      text: '',
      options: [{id: 1, value: '', correct: true}]
    })
  }

  removeQuestion(questionNumber: number) {
    if (this.totalNbQuestions() < 2) return;
    this.quiz.questions.splice(questionNumber, 1);
  }
}

const quizStore = new QuizStore();
export default quizStore