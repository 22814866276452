import {isDisposable, sendLoginMagicLink, signInWithGoogle} from "../authUtils";
import React, {useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";

export const CustomSignIn: React.FC = () => {

    const [email, setEmail] = useState<string>()

    const navigate = useNavigate();

    const handleGoogleSignIn = () => {
        signInWithGoogle().then(result => {
            navigate('/', {replace: true})
        })
    }

    const handleSignInWithMagicLinkClick = async (event: any) => {
        event.preventDefault()

        if (!email) {
            toast.error("Email required", {
                position: "top-center",
                theme: "colored"
            })

            return;
        }

        if (await isDisposable(email)) {
            toast.error("Email is invalid", {
                position: "top-center",
                theme: "colored"
            })
            return;
        }

        toast.promise(
            sendLoginMagicLink(email),
            {
                pending: "Loading ...",
                error: "Oops! There was a problem sending your magic link. Please try another method",
                success: "Login link Sent ✨ Check your email"
            }, {
                position: "top-center",
                theme: "colored"
            }
        )

    }

    return <>
        <div className={"container"}>
            <ToastContainer/>
            <div
                className={"d-flex flex-column align-items-center border border border-2 rounded-2 row-gap-3 p-4 bg-white"}>
                <div className={"d-flex flex-column align-items-center"}>
                    <h3>
                        Sign in to your account
                    </h3>
                    <span>
            <small
                className="text-muted">Login to your account for a faster checkout.</small>
          </span>
                </div>

                <div className={"w-100"}>
                    <button className="btn btn-outline-danger w-100" onClick={handleGoogleSignIn}>
                        <i className="fa-brands fa-google me-3"></i>Sign in with google
                    </button>
                </div>

                <div>
                    Or, sign in with your email
                </div>

                <form className={"w-100 d-flex flex-column row-gap-3"} onSubmit={handleSignInWithMagicLinkClick}>
                    <div className="form-floating mb-3 w-100">
                        <input type="email"
                               value={email}
                               onChange={(event) => setEmail(event.target.value)}
                               className="form-control w-100" id="floatingInput"
                               placeholder="name@example.com"/>
                        <label htmlFor="floatingInput">Email address</label>
                    </div>

                    <div className={"w-100"}>
                        <button type="submit"
                                className="btn btn-primary btn-sm w-100">Sign in
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </>
}