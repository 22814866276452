import {LandingContent} from "./landing-content";
import HeaderComponent from "../Header/components";
import {BottomComponent} from "../../../ChatApp/Components/Bottom/component";

export const LandingPage: React.FC = () => {
  return <div className={"vh-100 d-flex flex-column justify-content-between"}>
    <HeaderComponent/>
    <LandingContent/>
    <BottomComponent/>
  </div>
}