export const generateUserStripe = async (uid: string, priceId: string): Promise<any> => {
    try {
        const response = await fetch(`/.netlify/functions/customer-stripe/customer-stripe?uid=${uid}&priceId=${priceId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            cache: "reload"
        });
        if (!response.ok) {
            console.log('Network response was not ok');
            return null;
        }

    const responsePromise = await response.json()
    return (responsePromise)
  } catch (error) {
    console.error('Internal Error', error);
    return null;
  }
};