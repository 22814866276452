import {SubscriptionPlan} from "../types";

const yearly = (monthly: number) => {
    return Math.round(12 * monthly * 0.7)
}

export const pricingData: SubscriptionPlan[] = [
    {
        title: "Starter",
        description: "For Beginners",
        benefits: [
            "15 Credits",
            "HD videos quality",
            "Square or vertical video format",
            "Access to all features",
        ],
        limitations: [
            "Watermark",
            "Limited customer support",
        ],
        prices: {
            monthly: 0,
            yearly: 0,
        },
        stripeIds: {
            monthly: null,
            yearly: null,
        },
    },
    {
        title: "Pro",
        description: "Unlock Advanced Features",
        benefits: [
            "1500 Credits/ month (up to 150 video)",
            "HD videos quality",
            "Square or vertical video format",
            "Access to all features",
            "No watermark",
            "Priority customer support",
        ],
        limitations: [],
        prices: {
            monthly: 14.99,
            yearly: yearly(14.99),
        },
        stripeIds: {
            monthly: process.env.REACT_APP_PUBLIC_STRIPE_PRO_MONTHLY_PLAN_ID!,
            yearly: process.env.REACT_APP_PUBLIC_STRIPE_PRO_YEARLY_PLAN_ID!,
        },
    },
    {
        title: "Business",
        description: "For Power Users",
        benefits: [
            "5000 Credits/ month (up to 500 video)",
            "HD videos quality",
            "Square or vertical video format",
            "Access to all features",
            "No watermark",
            "Priority customer support",
        ],
        limitations: [],
        prices: {
            monthly: 24.99,
            yearly: yearly(24.99),
        },
        stripeIds: {
            monthly: process.env.REACT_APP_PUBLIC_STRIPE_BUSINESS_MONTHLY_PLAN_ID!,
            yearly: process.env.REACT_APP_PUBLIC_STRIPE_BUSINESS_YEARLY_PLAN_ID!,
        },
    },
];

