export const haveEnoughCreditsFor = async (event: string, userId: string): Promise<boolean> => {
    try {
        const response = await fetch(`/.netlify/functions/available-credits/available-credits`, {
            method: 'POST',
            body: JSON.stringify({userId: userId, eventType: event}),
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            console.log('Network response was not ok');
            throw new Error("Unexpected problem");
        }

        const isPurchaseAllowed = await response.json()
        console.log(isPurchaseAllowed)
        return await isPurchaseAllowed
    } catch (error) {
        console.error('Internal Error', error);
        throw new Error("Unexpected problem");
    }
}
export const deductCredits = async (event: string, userId: string): Promise<boolean> => {
    try {
        const response = await fetch(`/.netlify/functions/deduct-credits/deduct-credits`, {
            method: 'POST',
            body: JSON.stringify({userId: userId, eventType: event}),
            headers: {
                'Content-Type': 'application/json'
            },
        });

        console.log("response", response)
        if (!response.ok) {
            console.log('Network response was not ok');
            throw new Error("Unexpected problem");
        }

        const newCredits = await response.json()
        console.log(newCredits)
        return await newCredits
    } catch (error) {
        console.error('Internal Error', error);
        throw new Error("Unexpected problem");
    }
}