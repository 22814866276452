import {useEffect, useState} from "react";
import useAuthState from "../../../Common/hooks/auth-state-hook";
import {getUserVideos, VideoLink} from "../../../services/storageService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

export const QuizGallery: React.FC = () => {

  const {currentUser} = useAuthState()
  const [userVideos, setUserVideos] = useState<VideoLink[] | null>([])

  useEffect(() => {
    if (currentUser?.uid) {
      getUserVideos(currentUser.uid).then((userVideos) => {
        setUserVideos(userVideos)
      })
    }
  }, [currentUser]);

  return <>
    <div className={"w-100 d-flex align-items-center justify-content-center"}>
      <div>
        <h2>Your latest generated videos</h2>
      </div>
    </div>
    <ul className="list-group overflow-auto" style={{maxHeight: '150px'}}>
      {userVideos?.length ? (
          userVideos.map((userVideo, index) =>
              <li key={index} className="list-group-item w-50">
                <div className="d-flex flex-row justify-content-between" key={index}>
                  <div>{userVideo.name.toLowerCase()}</div>
                  <a rel="noreferrer" target={"_blank"}
                     href={userVideo.downloadUrl}>
                    <FontAwesomeIcon icon={faDownload} type={"button"} size={"xl"}/>
                  </a>
                </div>
              </li>
          )
      ): (
          <>
            No previously generated videos.
          </>
        )}
    </ul>
  </>
  /*  return <>
      {userVideos && (
          userVideos.map((userVideo, index) => (
              <div className="row" key={index}>
                <div className={"col"}>{userVideo.name.toLowerCase()}</div>
                <a rel="noreferrer" target={"_blank"} className={"col"}
                   href={userVideo.downloadUrl}>Download</a>
              </div>
          ))
      )}
    </>*/
}