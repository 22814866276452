import './styles.css'
import {FAQComponent} from "../FAQ/component";
import {MainComponent} from "../Main/component";
import HeaderComponent from "../../../Common/components/Header/components";
import {GreetingComponent} from "../Greeting/component";
import {QuizGallery} from "../QuizGallery/quiz-gallery";
import {Protected} from "../../../Common/layout/protected";

export const QuizApp: React.FC = () => {
  return <Protected>
    <>
      <HeaderComponent/>
      <div className={"quizapp-container"}>
        <div className={"main-container"}>
          <GreetingComponent greetingComponentProps={{
            greeting: "Creating quiz videos has never been simpler.",
            title: "Create captivating quiz videos in seconds"
          }}/>
        </div>
        <div className="main-container">
          <QuizGallery></QuizGallery>
        </div>
        <div className={"main-container"}>
          <MainComponent></MainComponent>
        </div>
        <div className={"main-container"}>
          <FAQComponent></FAQComponent>
        </div>
      </div>
    </>
  </Protected>
}