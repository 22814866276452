import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import {logToNetlify} from "../../../../logging/logging";
import firebase from 'firebase/compat/app';


type FeatureCardProps = {
    title: string;
    userId: string | undefined;
    closeModal: () => void;
};

export function FeatureCard({title, userId, closeModal}: FeatureCardProps) {
    const submitFeatureSuggestion = () => {
        // analytics
        logToNetlify('get_video', title, userId)
        if (firebase.apps.length) {
            firebase.analytics().logEvent('suggest_feature', {
                suggested_feature: title,
            })
        }
        closeModal()
    }

    return (
        <button
            onClick={() => submitFeatureSuggestion()}
            className="btn btn-light shadow-sm p-4 d-flex flex-column align-items-center gap-3 w-100"
        >
            {/*<Icon className="text-primary" style={{ width: '2rem', height: '2rem' }} />*/}
            <h3 className="h5 mb-0 text-dark">{title}</h3>
        </button>
    );
}
