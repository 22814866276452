import {firebaseAuth} from "../../config/firebase-config";

export const getUserSession = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        resolve(user); // User is signed in
      } else {
        reject("No user is signed in"); // User is signed out
      }
      unsubscribe(); // Clean up the observer
    });
  });
};