import React, {useCallback, useEffect, useState} from "react";
import {loginWithEmailLink} from "../../../auth/authUtils";
import {useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import HeaderComponent from "../Header/components";
import {BottomComponent} from "../../../ChatApp/Components/Bottom/component";

const FinishSignIn = () => {
    const [email, setEmail] = useState<string>()
    const [showEmailForm, setShowEmailForm] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    const handleFinishLogin = useCallback(() => {
        setLoading(true)
        loginWithEmailLink().then((result) => {
            navigate('/', {replace: true})
        }).catch(error => {
            if (error?.reason === "MISSING_EMAIL") {
                setShowEmailForm(true)
            } else if (error?.reason === "NOT_A_SINGIN_LINK") {
                navigate("/login")
            } else {
                setShowError(true)
            }
        }).finally(() => {
            setLoading(false)
        })
    }, [navigate])

    useEffect(() => {
        handleFinishLogin()
    }, [handleFinishLogin]);

    const handleEmailConfirm = (event: any) => {
        if (email) {
            event.preventDefault()
            setShowError(false)
            window.localStorage.setItem('emailForSignIn', email);
            handleFinishLogin()
        }
    }
    return <div className={"vh-100 d-flex flex-column justify-content-between"}>
        <HeaderComponent/>
        <div className={"container-sm my-4"}>
            <ToastContainer/>
            <div
                className={"d-flex flex-column align-items-center border border-2 rounded-2 row-gap-3 p-4 bg-white"}>
                {loading && <div className={"d-flex flex-column align-items-center"}>
                    <h3 className={"d-flex flex-row align-items-center"}>
                        <div>Completing sign in</div>
                        <div className={"ms-2 spinner-border spinner-border-sm"}>
                            <span className={"sr-only"}></span>
                        </div>
                    </h3>
                </div>}

                {showEmailForm && <form className={"w-100 d-flex flex-column row-gap-3"} onSubmit={handleEmailConfirm}>
                    <h4>Confirm your email to continue</h4>
                    <div className="form-floating mb-3 w-100">
                        <input type="email"
                               value={email}
                               onChange={(event) => setEmail(event.target.value)}
                               className="form-control w-100" id="floatingInput"
                               placeholder="name@example.com"/>
                        <label htmlFor="floatingInput">Email address</label>
                    </div>

                    <div className={"w-100"}>
                        <button type="submit"
                                className="btn btn-primary btn-sm w-100">Sign in
                        </button>
                    </div>
                </form>}
                {
                    showError && <div className={"text-danger"}>
                        Unable to sign in. This can happen if the link is malformed, expired, or has already been used.
                    </div>
                }
            </div>
        </div>
        <BottomComponent/>
    </div>

};

export default FinishSignIn;
