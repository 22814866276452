import {useState} from "react";
import {AuthModal} from "../AuthModal/component";
import {GreetingComponent} from "../Greeting/component";
import {MainComponent} from "../Main/component";
import {FAQComponent} from "../FAQ/component";
import {BottomComponent} from "../Bottom/component";
import './styles.css'
import HeaderComponent from "../../../Common/components/Header/components";

const ChatApp: React.FC = () => {

  const authModalState = useState(false)

  return <>
    <AuthModal authModalState={authModalState}/>
    <HeaderComponent/>

    <div className={"app-container"}>
      <div className={"main-container"}>
        <GreetingComponent></GreetingComponent>
      </div>
      <div className={"main-container"}>
        <MainComponent authModalState={authModalState}></MainComponent>
      </div>
      <div className={"main-container"}>
        <FAQComponent></FAQComponent>
      </div>
    </div>
    <BottomComponent/>
  </>
}
export default ChatApp;
