export const getStripeUserSubscription = async (id: string): Promise<any> => {
    try {
        const response = await fetch(`/.netlify/functions/stripe-customer-subscription/stripe-customer-subscription?dbUserSubscription=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (!response.ok) {
            console.log('Network response was not ok');
            return null;
        }

        return await response.json()
    } catch (error) {
        console.error('Internal Error', error);
        return null;
    }
};