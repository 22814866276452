import html2canvas from "html2canvas";
import React from "react";
import {fetchFile} from "@ffmpeg/util";
import {FFmpeg} from "@ffmpeg/ffmpeg"
import quizStore, {QuizStore} from "../../store/quizStore";
import {base64ToFile} from "../../mappers/tts-audio/gc-tts-mapper";
import {Question} from "./quiz-form";
import {uploadVideo} from "../../services/storageService";
import {toDateInUsFormatWithTime} from "../../ChatApp/utils/date/dates";

export const enum FrameType {
  QUESTION,
  QUESTION_ANIMATION,
  TIMER,
  TIMER_START,
  ANSWER,
  INTRO,
  SILENT
}

export interface VideoFrame {
  frame: Blob | null;
  duration: number;
  frameType: FrameType;
  frameIndex: number;
  alt?: string;
  questionIndex?: number
}

export const captureQuizFrameLegacy = (frameType: FrameType, frameDuration: number, videoFrames: any[], quizRef: React.MutableRefObject<null>, frameIndex: number, alt?: string, questionIndex?: number) => {
  if (quizRef.current) {
    try {
      html2canvas(quizRef.current, {
        scale: 2,
        logging: false
      }).then(capturedCanvas => {
        capturedCanvas.toBlob(blob => {
          videoFrames.push({
            frame: blob,
            frameType: frameType,
            duration: frameDuration / 1000,
            frameIndex: frameIndex,
            alt: alt ?? null,
            questionIndex: questionIndex ?? null
          } as VideoFrame);
        }, 'image/jpeg', 0.6); // Adjust quality as needed
      })
    } catch (error) {
      console.log("Error from capture frame", error);
    }
  }
  return false;
};

/*const downloadRecording = (url: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = 'recording.mp4';
  a.click();
  window.URL.revokeObjectURL(url);
}*/

export class Utils {
  private quizStore: QuizStore;

  constructor(store: QuizStore) {
    this.quizStore = store;
  }

  /**
   * frameDuration: in milliseconds
   **/
  captureQuizFrame = async (frameType: FrameType, frameDuration: number, alt?: string, questionIndex?: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (quizStore.preview) {
        setTimeout(() => {
          resolve()
        }, frameDuration)
      } else if (this.quizStore.quizRef?.current) {
        console.log("capture frame start")
        try {
          html2canvas(this.quizStore.quizRef.current, {
            scale: 2,
            allowTaint: false,
            logging: true
          }).then(capturedCanvas => {
            capturedCanvas.toBlob(blob => {
              console.log('captured and pushed')
              this.quizStore.pushFrame({
                frame: blob,
                frameType: frameType,
                duration: frameDuration / 1000,
                frameIndex: this.quizStore.upgetFrameIndex(),
                alt: alt ?? null,
                questionIndex: this.quizStore.getCurrentQuestionIndex()
              } as VideoFrame);
              resolve();
            }, 'image/jpeg', 0.6); // Adjust quality as needed
          })
        } catch (error) {
          console.log("Error from capture frame", error);
        }
      } else {
        reject();
      }
    })
  }

  captureAnim = (timeline: any, frameType: FrameType): Promise<void> => {
    return new Promise((resolve) => {
      const capture = (time: number) => {
        const FPS = 25
        const interval = Math.round(1000 / FPS)

        if (time >= 3000) {
          timeline.seek(time)
          this.captureQuizFrame(frameType, interval).then(() => {
            resolve()
          })
          return;
        }

        timeline.seek(time)
        this.captureQuizFrame(frameType, interval).then(() => {
          capture(time + interval)
        })

      }
      capture(0)
    })
  }

  convertFramesToVideo = async (FPS = 30, userId?: string) => {
    if (!userId) return;

    const frames = this.quizStore.videoFrames
    try {
      // setEncodingOnProgress(true)
      // Load the FFmpeg core
      const ffmpeg = new FFmpeg()
      await ffmpeg.load();

      let numberOfFrames = 0

      const extractFrame = (stderrLine: string): number | null => {
        const match = stderrLine.match(/frame=\s*(\d+)/);
        const frame = match ? match[1] : null;
        if (frame) {
          return Number(frame)
        }
        return null
      }

      ffmpeg.on("log", ({type, message}) => {
        console.log("type", type)
        console.log("message", message)
        const parsedFrame = extractFrame(message)
        if (parsedFrame && numberOfFrames) {
          quizStore.setRenderProgress(Math.min(Math.round((parsedFrame / numberOfFrames) * 100), 100))
        }
      })

      // Initialize a variable to store the concat demuxer file content
      let concatFileContent = '';

      // interactions strats after 1 second,
      let videoTime = 0;
      let filterComplex = '';
      let audioMix: string[] = [];
      const sortedFrames =
          frames
          .sort((a, b) => a.frameIndex - b.frameIndex)

      const timers = new Map<number, boolean>();
      const questionsAudio = new Map<number, boolean>();
      let introAudioEncoded = false;
      const questionIndexStart = quizStore.quiz.intro.audio ? 4 : 3;
      sortedFrames.forEach((frame, index) => {
        if (frame.frameType === FrameType.SILENT) {
          videoTime += frame.duration * 1000
        } else if (frame.frameType === FrameType.QUESTION_ANIMATION) {
          // TO BE DONE
          if (frame.questionIndex !== undefined && frame.questionIndex !== null && !questionsAudio.get(frame.questionIndex)) {
            const audioDuration = quizStore.getQuestionDuration(frame.questionIndex)
            filterComplex += `;[${questionIndexStart + frame.questionIndex}:a]atrim=duration=${audioDuration},adelay=${videoTime}|${videoTime}[aud_${index}]`
            questionsAudio.set(frame.questionIndex, true)
            audioMix.push(`[aud_${index}]`)
          }
          videoTime += frame.duration * 1000;
        } else if (frame.frameType === FrameType.QUESTION) {
          /*          const duration = frame.duration * 1000 //TODO set to real duration of title
                    filterComplex += `;[${2 + frame.questionIndex!}:a]atrim=duration=${duration / 1000},adelay=${videoTime}|${videoTime},asetpts=N/SR/TB[aud_${index}]`
                    audioMix.push(`[aud_${index}]`)
                    videoTime += duration*/
        } else if (frame.frameType === FrameType.TIMER_START) {
          const duration = frame.duration * 1000
          // const duration = frame.duration * timer_duration * 1000
          // Once per question:
          if (frame.questionIndex !== undefined && frame.questionIndex !== null && !timers.get(frame.questionIndex)) {
            filterComplex += `;[1:a]atrim=duration=3,adelay=${videoTime}|${videoTime}[aud_${index}]`
            timers.set(frame.questionIndex, true)
            audioMix.push(`[aud_${index}]`)
          }
          videoTime += duration
        } else if (frame.frameType === FrameType.ANSWER) {
          const duration = frame.duration * 1000
          filterComplex += `;[2:a]adelay=${videoTime}|${videoTime}[aud_${index}]`
          audioMix.push(`[aud_${index}]`)
          videoTime += duration
        } else if (frame.frameType === FrameType.INTRO) {
          if (!introAudioEncoded && quizStore.quiz.intro.audio) {
            filterComplex += `;[3:a]adelay=${videoTime}|${videoTime}[aud_${index}]`
            introAudioEncoded = true
            audioMix.push(`[aud_${index}]`)
          }
          videoTime += frame.duration * 1000;
        }

      })

      // set video duration in ms
      numberOfFrames = FPS * (videoTime / 1000)

      // initialize sounds
      const questionTitleSounds: (string | undefined)[] = this.quizStore.quiz.questions.map((question: Question) => question.audio)

      // Write frames to FFmpeg's virtual file system and build the concat file content
      for (const videoFrame of frames) {
        const index = frames.indexOf(videoFrame);
        const data = await fetchFile(videoFrame.frame!);
        await ffmpeg.writeFile(`frame${index}.jpg`, data);

        // Add each frame and its duration to the concat file content
        // Assuming each image should be displayed for 2 seconds
        concatFileContent += `file 'frame${index}.jpg'\nduration ${videoFrame.duration}\n`;

        // console.log('concatFileContent', concatFileContent)
        // create sound for each question
        if (videoFrame.frameType === FrameType.QUESTION && videoFrame.alt) {
          // TODO text to speech
        }
      }


      // Add the last file again without specifying duration to avoid freezing on the last frame
      if (frames.length > 0) {
        concatFileContent += `file 'frame${frames.length - 1}.jpg'\n`;
      }

      // console.log('concatFileContent', concatFileContent.toString())
      // Write the concat file content to FFmpeg's virtual file system
      await ffmpeg.writeFile('input.txt', concatFileContent);

      // Write audio files, assuming they are accessible similar to videoFrames
      const timerSound = await fetchFile(require('../../QuizApp/Sounds/timer.mp3'));
      const revealSound = await fetchFile(require('../../QuizApp/Sounds/reveal.mp3'));

      if (quizStore.quiz.intro.audio) {
        const introU8array = await base64ToFile(quizStore.quiz.intro.audio!)
        await ffmpeg.writeFile(`intro_audio.mp3`, new Uint8Array(introU8array!));
      }

      await ffmpeg.writeFile('timerSound.mp3', timerSound);
      await ffmpeg.writeFile('revealSound.mp3', revealSound);

      for (let i = 0; i < questionTitleSounds.length; i++) {
        if (!questionTitleSounds[i]) continue;
        const u8array = await base64ToFile(questionTitleSounds[i]!)
        await ffmpeg.writeFile(`question_sound_${i + 1}.mp3`, new Uint8Array(u8array!));
        console.log('listDir', await ffmpeg.listDir('../'))
      }

      const questionsFilterComplex = questionTitleSounds.reduce<any[]>((filterAccumulated, sound, index) => {
        if (index > 0) {
          filterAccumulated.push('-i')
        }
        filterAccumulated.push(`question_sound_${index + 1}.mp3`);
        return filterAccumulated;
      }, ['-i'])

      const introAudio = quizStore.quiz.intro.audio ? ['-i', 'intro_audio.mp3'] : []

      console.log('questionsFilterComplex', questionsFilterComplex)

      console.log('amix length', audioMix.length)

      filterComplex = `[0:v]setpts=PTS-STARTPTS,fps=${FPS}[v]` + filterComplex + `;${audioMix.join('')}amix=inputs=${audioMix.length}[audio_mix]`
      // Execute the FFmpeg command using the concat demuxer to convert the images to a video

      console.log('args: ', [
        '-f', 'concat',
        '-safe', '0',
        '-i', 'input.txt', // Video input from concatenated images
        '-i', 'timerSound.mp3', // Audio input
        '-i', 'revealSound.mp3', // Audio input
        ...introAudio,
        ...questionsFilterComplex,
        '-filter_complex', filterComplex, // Corrected filter_complex
        '-map', '[v]',
        '-map', '[audio_mix]',
        '-shortest',
        '-c:v', 'libx264', // Video codec
        '-pix_fmt', 'yuv420p', // Pixel format specified once
        '-c:a', 'aac', // Audio codec
        'out.mp4' // Output file
      ])

      await ffmpeg.exec([
        '-f', 'concat',
        '-safe', '0',
        '-i', 'input.txt', // Video input from concatenated images
        '-i', 'timerSound.mp3', // Audio input
        '-i', 'revealSound.mp3', // Audio input
        ...introAudio,
        ...questionsFilterComplex,
        // '-i', 'questionSoundFile.mp3', // Audio input //TODO
        '-filter_complex', filterComplex, // Corrected filter_complex
        '-map', '[v]',
        '-map', '[audio_mix]',
        '-shortest',
        '-c:v', 'libx264', // Video codec
        '-pix_fmt', 'yuv420p', // Pixel format specified once
        '-c:a', 'aac', // Audio codec
        'out.mp4' // Output file
      ]);

      // Read the generated video file from the virtual file system
      const data = await ffmpeg.readFile('out.mp4');
      const blob = new Blob([data], {type: "video/mp4"});

      // Create a URL for the video file
      const videoURL = URL.createObjectURL(blob);

      // Attach video
      quizStore.videoPreviewURL = videoURL

      // Upload toFirebase
      const file = new File([blob], `quiz_${toDateInUsFormatWithTime(new Date())}.mp4`, {type: "video/mp4"});
      uploadVideo(file, userId)

      quizStore.finishDownloading()
      // Use this videoURL to display the video or download it
      // downloadRecording(videoURL);
    } catch (e) {
      console.log(e)
    }
  };
}