import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;

export type SubscriptionPlan = {
  title: string;
  description: string;
  benefits: string[];
  limitations: string[];
  prices: {
    monthly: number;
    yearly: number;
  };
  stripeIds: {
    monthly: string | null;
    yearly: string | null;
  };
};

export interface DbUserSubscriptionPlan {
  stripeSubscriptionId: string,
  stripeCurrentPeriodEnd?: Timestamp,
  stripeCustomerId: string,
  stripePriceId: string,
  credits: number
}

export interface StripeUserSubscriptionPlan {
  stripeCurrentPeriodEnd?: Timestamp;
  isPaid: boolean;
  interval: "month" | "year" | null;
  isCanceled?: boolean;
}

export type UserSubscriptionPlan =
    SubscriptionPlan
    & DbUserSubscriptionPlan
    & StripeUserSubscriptionPlan;

export enum PaidEvents {
  DOWNLOAD_TEXTING_STORY = "DOWNLOAD_TEXTING_STORY",
  GENERATE_RANDOM_QUIZ = "GENERATE_RANDOM_QUIZ",
  DOWNLOAD_QUIZ_VIDEO = "DOWNLOAD_QUIZ_VIDEO"
}