import './styles.css'
import anime from 'animejs';
import {ReactState} from "../../../ChatApp/utils/types/types";
import {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {CaptureStateStatus} from "../../../store/quizStore";
import {useStores} from "../../../store";
import {FrameType, Utils} from "../../utils/capture-utils";

export const Animation: React.FC<{
  question: any,
  playAnimationState: ReactState<boolean>
}> =
    observer(({question, playAnimationState}) => {

      const {quizStore} = useStores();

      const [wordIndex, setWordIndex] = useState(-1)

      const utils = new Utils(quizStore)

      const wordCount = question.text.trim().split(' ').length;

      const {
        captureState,
        captureNext,
      } = quizStore

      const recordAnimation = useCallback(
          (): Promise<void> => {
            const words = question.text.split(' ')
            return new Promise(
                (resolve) => {
                  let timeline = anime.timeline({
                    loop: false, autoplay: false,
                  })
                  words.forEach((word: string, index: number) => {
                    timeline.add({
                      targets: `.ml4 .letters-${index + 1}`,
                      opacity: ml4.opacityIn,
                      scale: ml4.scaleIn,
                      duration: ml4.durationIn
                    }).add({
                      targets: `.ml4 .letters-${index + 1}`,
                      opacity: 0,
                      scale: ml4.scaleOut,
                      duration: ml4.durationOut,
                      easing: "easeInExpo",
                      delay: ml4.delay
                    })
                  })
                  utils.captureAnim(timeline, FrameType.QUESTION_ANIMATION).then(() => {
                    resolve()
                  })
                }
            )
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [])

      const recordQuestionTitlePromise = () => {
        setWordIndex(prev => prev + 1)
      }

      useEffect(() => {
        if (wordIndex === -1) return
        if (wordIndex >= wordCount) {
          captureNext()
          setWordIndex(-1)
          return
        } else {
          utils.captureQuizFrame(
              FrameType.QUESTION_ANIMATION,
              question.timepoints ? question.timepoints[wordIndex]?.duration * 1000 : 200
          ).then(() => {
            recordQuestionTitlePromise()
          })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [wordIndex]);

      useEffect(() => {
        if (captureState.getStatus() === CaptureStateStatus.QUESTION_TITLE) {
          console.log("TITLE: capture question (1)", captureState.getStatus())
          /*                    recordAnimation().then(() => {
                                    captureNext()
                                  }
                              )*/
          recordQuestionTitlePromise()
        }
      }, [captureNext, captureState, captureState.status, recordAnimation]);

      useEffect(() => {
        if (playAnimationState[0]) {
          recordQuestionTitlePromise()
          // recordAnimation()
          playAnimationState[1](false)
        }
      }, [playAnimationState, recordAnimation]);

      var ml4 = {
        opacityIn: [0, 1],
        scaleIn: [0.2, 1],
        scaleOut: 3,
        durationIn: 100,
        durationOut: 75,
        delay: 62.5
      };

      function isHighlighted(index: number): string {
        if (index === wordIndex) {
          return 'highlited'
        }
        return ''
      }

      return <>
        <h2 className={"question-title question-ellipsis"}>
          {question.text.split(' ').map((word: string, index: number) => (
              <span key={index}
                    className={`letters letters-${index + 1} ${isHighlighted(index)}`}>{word} </span>
          ))}
        </h2>
      </>
    })