// TODO: Fix this when we turn strict mode on.
import {StripeUserSubscriptionPlan, UserSubscriptionPlan} from "../types";
import {getUserSubscription} from "./subscriptionsService";
import {pricingData} from "../config/subscriptions";
import {getStripeUserSubscription} from "./getStripeUserSubscription";

export async function getUserSubscriptionPlanById(
    userId: string
): Promise<UserSubscriptionPlan> {
    if (!userId) throw new Error("Missing parameters");

    let dbUserSubscription = await getUserSubscription(userId)

    // Check if user is on a paid plan.
    const isPaid = !!(
        dbUserSubscription?.stripePriceId &&
        dbUserSubscription?.stripeCurrentPeriodEnd &&
        dbUserSubscription?.stripeCurrentPeriodEnd.toMillis() + 86_400_000 > Date.now()
    );

    // Find the pricing data corresponding to the user's plan
    const userPlan =
        pricingData.find((plan) => plan.stripeIds.monthly === dbUserSubscription?.stripePriceId) ||
        pricingData.find((plan) => plan.stripeIds.yearly === dbUserSubscription?.stripePriceId);

    const subscriptionPlan = isPaid && userPlan ? userPlan : pricingData[0]

    const interval = isPaid
        ? userPlan?.stripeIds.monthly === dbUserSubscription?.stripePriceId
            ? "month"
            : userPlan?.stripeIds.yearly === dbUserSubscription?.stripePriceId
                ? "year"
                : null
        : null;

    let isCanceled = false;

    if (isPaid && dbUserSubscription.stripeSubscriptionId) {
        const stripePlan = await getStripeUserSubscription(dbUserSubscription.stripeSubscriptionId)
        isCanceled = stripePlan.cancel_at_period_end
    }

    const stripeUserSubscription: StripeUserSubscriptionPlan = {
        stripeCurrentPeriodEnd: dbUserSubscription?.stripeCurrentPeriodEnd,
        isPaid,
        interval,
        isCanceled
    }

    return {
        ...subscriptionPlan,
        ...dbUserSubscription,
        ...stripeUserSubscription
    }
}
