import './styles.css'
import '../../../index.css'

interface GreetingComponentProps {
  title: string;
  greeting: string;
}

export const GreetingComponent: React.FC<{
  greetingComponentProps: GreetingComponentProps
}> = ({greetingComponentProps}) => {
  const {title, greeting} = greetingComponentProps
  return <div className={"greeting-container"}>
    <div className={"greeting-title"}>
      <h2>{title}</h2>
      <p>{greeting}</p>
    </div>
    <div className="greeting-title">
      <div className="btn btn-link">
        <a href="https://insigh.to/b/convoclip" target="_blank" rel="noreferrer">
          Suggest a feature/ report a problem
        </a>
      </div>
      {/*<SupportButton/>*/}
    </div>
  </div>
}