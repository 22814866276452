export const generateRandomQuiz = async (topic: string | undefined, prompt: string | undefined, language: string | undefined): Promise<any> => {
  try {
    const response = await fetch('/.netlify/functions/chat-gpt/chat-gpt', {
      method: 'POST',
      body: JSON.stringify({topic: topic, prompt: prompt, language: language}),
      headers: {
        'Content-Type': 'application/json'
      },
    });

    if (!response.ok) {
      console.log('Network response was not ok');
      return null;
    }

    const data = await response.json()
    console.log(data)
    return data
  } catch (error) {
    console.error('Internal Error', error);
    return null;
  }
};