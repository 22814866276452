import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {ReactState} from "../../utils/types/types";
import {topupCredits} from "../../../actions/topup-credits";
import './styles.css'

export const TopupCreditModal: React.FC<{
    topupCreditModaleState: ReactState<boolean>,
    userId: string | undefined;
}> = ({
          topupCreditModaleState,
          userId
      }) => {

    const [isOpen, setIsOpen] = topupCreditModaleState;
    const [topupAmount, setTopupAmount] = useState<number>(300);
    const [buyCreditsLoader, setBuyCreditsLoader] = useState(false);
    const handleClose = () => setIsOpen(false);

    const promoCode = "LAUNCH"; // Example promo code

    const handleTopupAmountChange = (value: number) => {
        setTopupAmount(value - (value % 100))
    }

    const topupCreditsUrl = () => {
        if (userId) {
            setBuyCreditsLoader(true)
            topupCredits(userId, topupAmount).then((topupUrl) => {
                setBuyCreditsLoader(false)
                window.open(topupUrl, '_blank', 'noopener,noreferrer');
            })
        }
    }

    const addTopupAmount = () => {
        if (topupAmount <= 9900) {
            setTopupAmount((topup) => topup + 100)
        }
    }

    const deductTopupAmount = () => {
        if (topupAmount >= 400) {
            setTopupAmount((topup) => topup - 100)
        }
    }

    const handleInputChange = (event: any) => {
        // Use regex to allow only numbers (including negative, optional)
        const newValue = Number(event.target.value.replace(/[^0-9]/g, ''));
        console.log("newValue", newValue)
        setTopupAmount(newValue);
    }

    return (
        <Modal show={isOpen} onHide={handleClose} centered>
            <ModalHeader closeButton>
                <h5 className="modal-title">🚀 Purchase Convoclip credits</h5>
            </ModalHeader>
            <ModalBody className="text-center">
                <div className={"expiration-card d-flex justify-content-center align-items-centerr"}>
                    <p className={"text-secondary"}> 💳 Convoclip credits will not expire</p>
                </div>
                <div className={"input-group-sm d-flex w-100 justify-content-center"}>
                    <input onChange={handleInputChange} min="300" max="10000" value={topupAmount} type="number"
                           className={"input-topup form-control text-end w-50"}/>
                    <div className={"d-flex flex-column"}>
                        <button onClick={addTopupAmount} className={"btn-topup btn btn-sm btn-success"}>+</button>
                        <button onClick={deductTopupAmount} className={"btn-topup btn btn-sm btn-danger"}>-</button>
                    </div>
                </div>
                <div className="promo-section my-3">
                    <p className="fw-bold mb-1">🎁 Use Promo Code:</p>
                    <div className="promo-code bg-light py-2 px-3 rounded d-inline-block">
                        <code className="text-danger fw-bold">{promoCode}</code>
                    </div>
                    <p className="text-muted mt-2">Apply at checkout to get 10% off your next top-up!</p>
                </div>

                <div className={"w-100"}>
                    <input type="range"
                           className="form-range"
                           min={300}
                           max={10000}
                           id="typingSpeed"
                           value={topupAmount}
                           onChange={(event) => handleTopupAmountChange(Number(event.target.value))}/>
                </div>
                {topupAmount < 300 &&
                    <p className={"text-danger"}>Topup amount should be greater than 300</p>
                }
                {topupAmount > 10000 &&
                    <p className={"text-danger"}>Maximum Topup amount is 10,000</p>
                }
                <Button
                    variant="success"
                    className="mt-3 w-100"
                    disabled={buyCreditsLoader || topupAmount < 300 || topupAmount > 10000}
                    onClick={() => {
                        topupCreditsUrl()
                    }}
                >
                    {buyCreditsLoader ? (
                            <div className={"spinner-border-sm spinner-border"}>
                                <span className="sr-only"></span>
                            </div>) :
                        <>
                            💵 Buy {topupAmount} credits Now
                        </>
                    }

                </Button>
            </ModalBody>
        </Modal>
    );
};