import {useState, useEffect} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import 'firebase/analytics';
import {firebaseAuth} from "../../config/firebase-config";

const useAuthState = () => {
  const [currentUser, setCurrentUser] = useState<firebase.UserInfo | null>(null);
  const [idToken, setIdToken] = useState<string>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
      setCurrentUser(user);
      user?.getIdToken().then(setIdToken)
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const logout = async () => {
    try {
      await firebase.auth().signOut();
    } catch (error) {
      console.error("Failed to logout: ", error);
    }
  };

  return {currentUser, idToken, loading, logout};
};

export default useAuthState;