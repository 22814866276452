import {useEffect, useState} from "react";
import {FrameType, Utils} from "../../../utils/capture-utils";
import {useStores} from "../../../../store";
import {observer} from "mobx-react-lite";
import {CaptureStateStatus} from "../../../../store/quizStore";
import './styles.css'

export const Intro: React.FC<{ intro: any }> =
    observer(({intro}) => {

      const wordCount = intro.text.trim().split(' ').length;
      const {quizStore} = useStores();
      const [wordIndex, setWordIndex] = useState(-1)

      const utils = new Utils(quizStore)

      const {
        captureState,
        captureNext,
      } = quizStore

      const recordIntroPromise = () => {
        setWordIndex(prev => prev + 1)
      }

      useEffect(() => {
        if (wordIndex === -1) return
        if (wordIndex >= wordCount) {
          captureNext()
          setWordIndex(-1)
          return
        } else {
          console.log("capture intro ?")
          utils.captureQuizFrame(
              FrameType.INTRO,
              intro.timepoints ? intro.timepoints[wordIndex]?.duration * 1000 : 200
          ).then(() => {
            recordIntroPromise()
          })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [wordIndex]);

      useEffect(() => {
        if (captureState.getStatus() === CaptureStateStatus.QUIZ_INTRO) {
          console.log("INTRO: capture intro (1)", captureState.getStatus())
          recordIntroPromise()
        }
      }, [captureNext, captureState, captureState.status]);

      const isHighlighted = (index: number): string => {
        if (index === wordIndex) {
          return 'highlited'
        }
        return ''
      }
      return <>
        <h2 className={"intro-title intro-ellipsis"}>
          {intro.text.split(' ').map((word: string, index: number) => (
              <span key={index}
                    className={`intro-letters ${isHighlighted(index)}`}>{word} </span>
          ))}
        </h2>
      </>
    })
