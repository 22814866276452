import React, {useState, useRef, useEffect} from 'react';
import './styles.css'

export const AudioPlayer: React.FC<{ src: string }> = ({src}) => {
  const [audioSrc, setAudioSrc] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Load the audio file when the component mounts
  useEffect(() => {
    const loadAudio = async () => {
      try {
        // Dynamically import the audio file
        const audioModule = await import((`../../../QuizApp/Sounds/voices/${src}`)); // Adjust the path as necessary
        setAudioSrc(audioModule.default); // Use audioModule.default to access the file
      } catch (error) {
        console.error('Error loading audio file:', error);
      }
    };

    loadAudio();
  }, [src]); // Empty dependency array means this effect runs once when the component mounts

  return (
      <>
        {audioSrc && (
            <audio className={"audio-width"} controls ref={audioRef} src={audioSrc} preload="metadata"
                   style={{height: "40px"}}/>
        )}
      </>
  );
};