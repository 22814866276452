import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import './styles.css'
import useAuthState from "../../hooks/auth-state-hook";
import useDbUserSubscriptionState from "../../hooks/db-user-subscription-hook";
import {TopupCreditModal} from "../../../ChatApp/Components/TopupCreditsModal/component";
import React, {useState} from "react";
import {Pricing} from "../Pricing/component";


const HeaderComponent = () => {
    const {currentUser, logout} = useAuthState()
    const {dbUserSubscription} = useDbUserSubscriptionState()
    const showTopupCreditModalState = useState(false)
    const pricingModalState = useState(false)

    const showTopupCreditModal = () => {
        showTopupCreditModalState[1](true)
    }

    const showPricingModalState = () => {
        pricingModalState[1](true)
    }

    return (<>
            <TopupCreditModal topupCreditModaleState={showTopupCreditModalState}
                              userId={currentUser?.uid}></TopupCreditModal>
            <Pricing pricingModalState={pricingModalState}></Pricing>
            <nav className="navbar navbar-expand-lg navbar-light navbar-custom-custom">
                <div className="container-fluid">
                    {/* Logo on the Left */}
                    <a className="navbar-brand" href="/">
                        <img src={require("../../../assets/logo_chatvisio_square.png")} height="60px"
                             width="60px"
                             alt={"Logo"}></img>
                        <span className="brand-name">ConvoClip</span>
                    </a>
                    <div className="d-flex align-items-center ms-auto">
                        {/* Toggler/Collapsible Button for Smaller Screens */}
                        {currentUser &&
                                <div
                                    className={"d-block d-lg-none navbar-text text-white text-right me-3"}>
                                    <div
                                        className={"navbar-text text-white text-right me-3 m-auto bg-gradient border-1 rounded-2 px-2"}>
                                        <div
                                            className={"d-flex gap-2 align-middle align-items-center justify-content-center"}>
                                            <span>Credits: <strong>{dbUserSubscription?.credits ?? 0}</strong></span>
                                            <button className={"btn btn-sm btn-danger"}
                                                    onClick={showTopupCreditModal}>Topup
                                            </button>
                                            <button className={"btn btn-sm btn-dark"}
                                                    onClick={showPricingModalState}>Upgrade
                                            </button>
                                        </div>
                                    </div>
                                </div>

                        }
                        <button className="navbar-toggler custom-toggler" type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarNav" aria-controls="navbarNav"
                                aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>

                    {/* Navigation Options on the Right */}
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav">
                            {currentUser ? <>
                                <div
                                    className={"navbar-text text-white text-right me-3 d-none d-lg-block m-auto bg-gradient border-1 rounded-2 px-2"}>
                                <div
                                        className={"d-flex gap-2 align-middle align-items-center justify-content-center"}>
                                        <span>Credits: <strong>{dbUserSubscription?.credits ?? 0}</strong></span>
                                        <button className={"btn btn-sm btn-danger"} onClick={showTopupCreditModal}>Topup</button>
                                        <button className={"btn btn-sm btn-dark"} onClick={showPricingModalState}>Upgrade</button>
                                    </div>
                                </div>
                                <li className="nav-item">
                                    <a className="nav-link" href="/profile">
                                        <span className={"menu-options"}>Account settings</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/login" onClick={logout}>
                                        <span className={"menu-options"}>Logout</span></a>
                                </li>
                            </> : <>
                                <li className="nav-item">
                                    <a className="nav-link" href="/login">
                                        <span className={"menu-options"}>Login</span></a>
                                </li>
                            </>}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default HeaderComponent;