import {format, parse} from "date-fns";

export const DATE_US_FORMAT = "yyyy-MM-dd"
export const HUMAN_FORMAT = "d MMMM yyyy"
export const DATE_US_FORMAT_WITH_TIME = "yyyy-MM-dd_HH:mm";

export const toDateInUsFormat = (date: Date): string => {
  return format(date, DATE_US_FORMAT);
}

export const toDateInUsFormatWithTime = (date: Date): string => {
  return format(date, DATE_US_FORMAT_WITH_TIME);
}

export const toDateInHumanFormat = (date: Date): string => {
  return format(date, HUMAN_FORMAT);
}

export const fromHumanToUsFormat = (dateString: string): string => {
  const parsedDate = parse(dateString, HUMAN_FORMAT, new Date());
  return format(parsedDate, DATE_US_FORMAT);
};

export const to12Hour = (time: string): string => {
  let [hours, minutes] = time.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;
  return `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;
};