import './styles.css'

export const FAQComponent = () => {
  return <div className={"faq-container"}>
    <div className={"faq-title"}>
      <h1>FAQ</h1>
    </div>
    <div className="accordion" id="accordionParent">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button className="accordion-button" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            1. What is ConvoClip?
          </button>
        </h2>
        <div id="collapseOne" className="accordion-collapse collapse show"
             aria-labelledby="headingOne" data-bs-parent="#accordionParent">
          <div className="accordion-body">
            ConvoClip is an app that allows you to transform your written
            conversations into engaging video clips. It offers a unique way to bring dialogues to
            life by adding visual elements and customization options.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingTwo">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            2. How does ConvoClip work?
          </button>
        </h2>
        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
             data-bs-parent="#accordionParent">
          <div className="accordion-body">
            To use ConvoClip, simply write your conversation in the left side of the app, customize
            it with various
            features such as profile pictures and message details, and then export it as a video
            clip. The app provides intuitive tools to make the process easy and enjoyable.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingThree">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThree" aria-expanded="false"
                  aria-controls="collapseThree">
            3. What can I customize in my conversations on ConvoClip?
          </button>
        </h2>
        <div id="collapseThree" className="accordion-collapse collapse"
             aria-labelledby="headingThree" data-bs-parent="#accordionParent">
          <div className="accordion-body">
            You can personalize every aspect of your conversations, including profile pictures,
            message content, message status (e.g., delivered, read), and more. ConvoClip offers a
            wide range of customization options to make your videos unique.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="heading4">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse4" aria-expanded="false"
                  aria-controls="collapse4">
            4. Can I add media to my conversations in ConvoClip?
          </button>
        </h2>
        <div id="collapse4" className="accordion-collapse collapse"
             aria-labelledby="heading4" data-bs-parent="#accordionParent">
          <div className="accordion-body">
            You can personalize every aspect of your conversations, including profile pictures,
            message content, message status (e.g., delivered, read), and more. ConvoClip offers a
            wide range of customization options to make your videos unique.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="heading5">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse5" aria-expanded="false"
                  aria-controls="collapse5">
            5. Is ConvoClip available on all platforms?
          </button>
        </h2>
        <div id="collapse5" className="accordion-collapse collapse"
             aria-labelledby="heading5" data-bs-parent="#accordionParent">
          <div className="accordion-body">
            Yes, ConvoClip is accessible via web browsers on all platforms, including desktop
            computers, laptops, tablets, and mobile devices. There's no need to download any
            additional software or apps.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="heading6">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse6" aria-expanded="false"
                  aria-controls="collapse5">
            6. Is there a free plan?
          </button>
        </h2>
        <div id="collapse6" className="accordion-collapse collapse"
             aria-labelledby="heading6" data-bs-parent="#accordionParent">
          <div className="accordion-body">
            Yes, Convoclip offers some free credits upon first login, so you can try out the platform and explore its features without any cost.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="heading7">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse7" aria-expanded="false"
                  aria-controls="collapse7">
            7. How can I get support if I encounter any issues with ConvoClip?
          </button>
        </h2>
        <div id="collapse7" className="accordion-collapse collapse"
             aria-labelledby="heading7" data-bs-parent="#accordionParent">
          <div className="accordion-body">
            If you have any questions or encounter any issues while using ConvoClip, please don't
            hesitate to reach out to our support team. You can contact us through the app.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="heading8">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse8" aria-expanded="false"
                  aria-controls="collapse8">
            8. Is my data safe and secure with ConvoClip?
          </button>
        </h2>
        <div id="collapse8" className="accordion-collapse collapse"
             aria-labelledby="heading8" data-bs-parent="#accordionParent">
          <div className="accordion-body">
            Yes, ConvoClip takes data security and privacy seriously. We use encryption and other
            security measures to protect your personal information and conversations. Your data is
            stored securely and will not be shared with third parties.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="heading9">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse9" aria-expanded="false"
                  aria-controls="collapse9">
            9. Can I share my ConvoClip videos on social media?
          </button>
        </h2>
        <div id="collapse9" className="accordion-collapse collapse"
             aria-labelledby="heading9" data-bs-parent="#accordionParent">
          <div className="accordion-body">
            Absolutely! ConvoClip allows you to share your videos directly to popular social media
            platforms such as Facebook, Instagram, and Twitter. Share your creations with friends,
            family, and followers with just a few clicks.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="heading10">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapse10" aria-expanded="false"
                  aria-controls="collapse10">
            10. How often is ConvoClip updated with new features?
          </button>
        </h2>
        <div id="collapse10" className="accordion-collapse collapse"
             aria-labelledby="heading10" data-bs-parent="#accordionParent">
          <div className="accordion-body">
            We're constantly working to improve ConvoClip and add new features based on user
            feedback and industry trends. Updates are released regularly to ensure that you have the
            best possible experience with the app.
          </div>
        </div>
      </div>
    </div>
  </div>
}