export const searchPexelsPhotos = async (search = "science", count = 5): Promise<any> => {
  try {
    const response = await fetch(`/.netlify/functions/search-pexels/search-pexels?search=${search}&count=${count}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    if (!response.ok) {
      console.log('Network response was not ok');
      return null;
    }

    return (await response.json())
  } catch (error) {
    console.error('Internal Error', error);
    return null;
  }
};