import styled, {css} from "styled-components";

export const Choice = styled.div<{
  $showAnswer: boolean,
  $isCorrect: boolean
}>`${props =>
    css`
      @import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Bungee+Spice&family=Cookie&family=Poetsen+One&display=swap');
      font-weight: 400;
      font-size: 1.2rem;
      font-style: normal;
      text-transform: uppercase;
      font-family: "Poetsen One", sans-serif;
      color: #d7b921; /* Inner color */
      text-align: center;
      text-shadow: -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;

      background-color: ${props.$showAnswer && props.$isCorrect ? '#43a913' : 'none'};

      width: 100%; /* Adjust as needed */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`