import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    faArrowRight,
    faClapperboard,
    faComment,
    faLightbulb
} from "@fortawesome/free-solid-svg-icons";
export const LandingContent = () => {

    return <>
        <div className={"container py-5"}>
            <div className={"d-flex flex-column row-gap-3 align-items-center"}>
                <div
                    className={"w-100 d-flex flex-column align-items-center border border-1 rounded-2 bg-light p-3 shadow-sm"}>
                    <h2>Video Tools <span><FontAwesomeIcon icon={faClapperboard} color={"#218577"}/></span>
                    </h2>
                    <p>Convoclip offers powerful tools to simplify video creation. Effortlessly turn simple input into engaging video content!</p>
                </div>
                <div
                    className={"w-100 row gap-3 column-gap-3 justify-content-center bg-light border border-1 rounded-2 p-3 shadow-sm"}>
                    <div className="card col-sm" style={{width: '18rem'}}>
                        <div className="card-body">
                            <div className={"d-flex flex-row align-items-center justify-content-between"}>
                                <h5 className="card-title">Texting story <FontAwesomeIcon icon={faComment}
                                                                                          color={"#47d3ff"}/></h5>
                            </div>
                            <p className="card-text">
                                Create Authentic Texting Story Videos That Feel Like Reality!
                            </p>
                            <a href="/texting-story" className="btn btn-outline-primary">Get
                                started <span><FontAwesomeIcon
                                    icon={faArrowRight}/></span></a>
                        </div>
                    </div>
                    <div className="card col-sm" style={{width: '18rem'}}>
                        <div className="card-body">
                            <div className={"d-flex flex-row align-items-center justify-content-between"}>
                                <h5 className="card-title">Quiztory <FontAwesomeIcon icon={faLightbulb}
                                                                                     color={"#e0c411"}/></h5>
                                <span
                                    className={"border border-light bg-secondary rounded-2 fs-6 text-white p-1"}>Coming soon</span>
                            </div>
                            <p className="card-text">Create a quiz video with subtitles, AI generated questions,
                                voices
                                over and more</p>
                            <a href={"/quiz"} className="disabled btn btn-outline-primary">Get
                                started <span><FontAwesomeIcon
                                    icon={faArrowRight}/></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}