import React from "react";
import {ReactState} from "../../utils/types/types";
import {Button, Modal, ModalBody, ModalHeader} from "react-bootstrap";
// @ts-ignore

import './styles.css'
import {FeatureCard} from "./FeatureCard/component";

export const SupportModale: React.FC<{
    supportModalState: ReactState<boolean>,
    userId: string | undefined;
}> = ({
          supportModalState,
          userId
      }) => {

    const features = [
        {id: 'keyboard', title: 'Display keyboard', icon: 'Keyboard while typing'},
        {id: 'hesitation', title: 'Hesitation while typing', icon: 'Hesitation'},
        {id: 'History', title: 'Video history', icon: 'users'},
        {id: 'Group', title: 'Group chat', icon: 'Group'},
    ];

    return (

        <Modal
            show={supportModalState[0]}
            aria-labelledby="Support"
            aria-describedby="Support"
        >
            <ModalHeader>
                    <h3>
                        Thank You for Downloading!
                    </h3>
            </ModalHeader>
            <ModalBody>
                <div className={"d-flex flex-column align-items-center"}>
                    <p>What's the next feature you'd like to have?</p>
                </div>
                {features.map(feature => (
                        <FeatureCard
                            key={feature.id}
                            userId={userId}
                            title={feature.title}
                            closeModal={() => supportModalState[1](false)}
                        />
                ))}


            </ModalBody>
            <div className="next-time">
                <Button variant="link" onClick={() => supportModalState[1](false)}>Maybe next
                    time</Button>
            </div>
        </Modal>

    )
}