import {useState, useEffect} from 'react';
import {DbUserSubscriptionPlan} from "../../types";
import useAuthState from "./auth-state-hook";
import {getUserSubscription} from "../../services/subscriptionsService";

const useDbUserSubscriptionState = () => {
  const {currentUser} = useAuthState()

  const [dbUserSubscription, setDbUserSubscription] = useState<DbUserSubscriptionPlan | null>(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  useEffect(() => {
    setSubscriptionLoading(false)
    if (currentUser) {
      getUserSubscription(currentUser.uid).then(userSubscription => {
        console.log("userSub:", userSubscription)
        setDbUserSubscription(userSubscription)
        setSubscriptionLoading(false)
      })
    }
  }, [currentUser]);


  return {currentUser, dbUserSubscription, subscriptionLoading};
};

export default useDbUserSubscriptionState;