import React from "react";
import './styles.css'

export const BottomComponent: React.FC = () => {
  return <>
    <div className="footer mt-auto">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-col">
              <h4>About ConvoClip</h4>
              <p>Convoclip is an online platform designed to help you create engaging video content effortlessly, with tools tailored to enhance your audience engagement.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-col middle">
              <h4>Important Links</h4>
              <ul className="list-unstyled li-space-lg">
                <li className="media">
                  <i className="fas fa-square"></i>
                  <div className="media-body">
                    <p>Visit our website
                      <a className="turquoise" href="https://convoclip.com"> convoclip.com</a>
                    </p>
                  </div>
                </li>
                <li className="media">
                  <i className="fas fa-square"></i>
                  <div className="media-body"><p>Read our
                    <a className="turquoise"
                       href="https://convoclip.com/terms-conditions" target="_blank"
                       rel="noreferrer"> Terms
                      & Conditions</a>,
                    <a className="turquoise"
                       href="https://convoclip.com/privacy-policy" target="_blank"
                       rel="noreferrer"> Privacy
                      Policy</a></p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}