import './styles.css'
import {observer} from "mobx-react-lite";
import {useStores} from "../../../store";
import {Question} from "../../utils/quiz-form";
import {generateRandomQuiz} from "../../../services/generateRandomQuiz";
import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus, faImage, faMinusCircle} from "@fortawesome/free-solid-svg-icons";
import {AudioPlayer} from "../../../Common/components/AudioPlayer/components";
import {voicesMap} from "../../Sounds/voices/voicesMap";
import {searchPexelsPhotos} from "../../../services/searchPhotos";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Define the interface for each voice object
export const QuizForm: React.FC = observer(
    () => {
      const {quizStore} = useStores()
      const {quiz, language, voice} = quizStore

      const imageInputRefs = useRef<(HTMLInputElement | null)[]>([])

      const [topic, setTopic] = useState<string>()
      const [userPromt, setUserPrompt] = useState<string>('')
      const [quizLoading, setQuizLoading] = useState<boolean>(false)

      const handleImageChange = (event: any, questionIndex: number) => {
        const file = event.target.files[0];
        if (file && file.type.substr(0, 5) === 'image') {
          const blob = new Blob([file], {type: "image/jpeg"});
          if (questionIndex > -1) {
            quizStore.quiz.questions[questionIndex].backgroundImg = URL.createObjectURL(blob);
          } else {
            quizStore.quiz.intro.backgroundImg = URL.createObjectURL(blob);
          }
          toast.success('Background image uploaded successfully', {
            position: "top-center",
            theme: "colored"
          })
        }
      };

      const handleUploadImage = (index: number) => {
        imageInputRefs.current![index]?.click();
      }

      useEffect(() => {
        quizStore.setSelectedVoice(voicesMap[language][0])
      }, [language, quizStore]);

      const isDownloading = () => {
        return quizStore.isDownloading()
      }

      const handleQuizChange = (partial: Partial<Question>, index: number) => {
        quizStore.updateQuizQuestion(index, partial)
      }

      const handleCorrectOptionChange = (questionIndex: number, optionId: number) => {
        quizStore.setCorrectAnswer(questionIndex, optionId)
      }

      const handleOptionChange = (questionIndex: number, optionId: number, event: any) => {
        quizStore.updateQuizOption(questionIndex, optionId, event.target.value)
      }

      const handleGenerateQuizClick = async () => {
        setQuizLoading(true)
        quizStore.videoPreviewURL = ''

        await toast.promise(generateRandomQuiz(topic, userPromt, quizStore.language).then(data => {
          if (data?.choices[0]?.message?.content) {
            quizStore.setQuiz(JSON.parse(data.choices[0].message.content))

            // load images
            try {
              searchPexelsPhotos(topic ?? 'science', quizStore.totalNbQuestions() + 1).then(response => {
                // Preload imgs
                const imageUrls = response?.photos?.map((photo: any) => photo.src.original)

                const downloadImages = async (imageUrls: any[]) => {
                  if (!imageUrls) return null
                  return await Promise.all(
                      imageUrls.map(async (url) => {
                        const response = await fetch(url);
                        const blob = await response.blob();
                        return URL.createObjectURL(blob); // Create a Blob URL
                      })
                  );
                };
                downloadImages(imageUrls).then((blobUrls) => {
                  if (blobUrls) {
                    quizStore.updateQuestionImages(blobUrls)
                  }
                  // Set loading to false
                  setQuizLoading(false)
                })
              })
            } catch (error) {
              console.log("Internal error")
            }
          }
        }), {
          pending: 'Our robots are busy generating your quiz.\nPlease wait 🛠️',
          success: 'Quiz generated successfully 👌',
          error: 'An unexpected error occured 🤯 please try again'
        }, {
          theme: "colored",
          position: "top-center"
        })
      }

      const handleAddOption = (questionIndex: number) => {
        quizStore.addQuizOption(questionIndex)
      }

      const handleRemoveOption = (questionIndex: number, id: number) => {
        quizStore.removeQuizOption(questionIndex, id);
      }

      const handleLanguageUpdate = (event: React.ChangeEvent<HTMLSelectElement>) => {
        quizStore.setLanguage(event.target.value)
      }

      const isActive = (name: string): boolean => {
        return name === voice?.name
      }

      const handleAddQuestionClick = () => {
        quizStore.addNewQuestion()
      }

      const handleRemoveQuestionClick = (questionIndex: number) => {
        quizStore.removeQuestion(questionIndex)
      }
      return <div className={"form-container"}>
        <ToastContainer/>
        <div className="quiz-section form-row align-items-center">
          <label className={"title-font"}>1/ Let's start by generating a quiz (or create
            your's)</label>
          <div className="form-floating">
            <select className="form-select" id="floatingSelect"
                    value={language}
                    aria-label="Floating label select example" onChange={handleLanguageUpdate}>
              <option value="english">English</option>
              <option value="french">French</option>
              <option value="spanish">Spanish</option>
              <option value="german">German</option>
            </select>
            <label htmlFor="floatingSelect">Choose language</label>
          </div>
          <div className="form-floating">
            <input disabled={isDownloading()} type="text"
                   className="form-control"
                   id="inlineFormTopic"
                   value={topic}
                   onChange={(event) => setTopic(event.target.value)}
                   placeholder="Quiz topic"/>
            <label htmlFor="inlineFormTopic">Topic</label>
          </div>
          <div className="form-floating">
            <input type="text"
                   disabled={isDownloading()}
                   className="form-control mb-2"
                   id="inlineFormUserPrompt"
                   maxLength={50}
                   value={userPromt}
                   onChange={(event) => setUserPrompt(event.target.value)}
                   placeholder="Give custom instructions: Exp. Easy questions"
            />
            <label htmlFor="inlineFormUserPrompt">Prompt (50 characters max)</label>
          </div>
          <div className="container  mb-2">
            <div className={"d-flex justify-content-end"}>
              <button className="btn btn-primary"
                      disabled={quizLoading || isDownloading()}
                      onClick={() => handleGenerateQuizClick()}>Generate quiz
              </button>
            </div>
          </div>
        </div>


        <div className="quiz-section">
          <label className={"title-font"}>2/ Select voice overs</label>
          <div className="list-group">
            {voicesMap[language].map(voice => (
                <div key={voice.name}
                     onClick={() => quizStore.setSelectedVoice(voice)}
                     className={`voiceover-option list-group-item list-group-item-action ${isActive(voice.name) ? 'active' : ''}`}>
                  <div className={"row align-items-center"}>
                    <div className={"col"}>{voice.name}</div>
                    <div className="col"
                         aria-current="true">
                      <AudioPlayer src={voice.src}></AudioPlayer>
                    </div>
                  </div>
                </div>
            ))}
          </div>
        </div>
        <div className={"quiz-section"}>
          <label className={"title-font"}>3/ Adjust (or create) your quiz</label>
          <div className="form-floating">
            <textarea
                value={quiz.intro.text}
                id="introTextArea"
                className="form-control"
                onChange={event => quizStore.updateQuiz({intro: {text: event.target.value}})}
            />
            <label htmlFor="introTextArea">Introduction</label>

            <label className="image-upload">
              <input className={"image-upload"}
                     type="file"
                     accept="image/*"
                     ref={(el) => imageInputRefs.current[0] = el}
                     onChange={(event) => handleImageChange(event, -1)}/>
            </label>
            <button className="inside-button" onClick={() => handleUploadImage(0)}>
              <FontAwesomeIcon icon={faImage} color={"#1cb9c8"}
              />
            </button>

          </div>
          <div>
            <span>Questions</span>
            <button className={"btn-icon"}
                    onClick={() => handleAddQuestionClick()}
            >
              <FontAwesomeIcon icon={faCirclePlus}
                               type={"button"}
                               color={"#157536"}
                               size={"xl"}></FontAwesomeIcon>
            </button>
          </div>
          {quiz.questions && quiz.questions.length > 0 ? (
              quiz.questions.map((question, questionIndex) => {
                return (
                    <div key={`question-${questionIndex}`}>
                      <div className="form-group">
                        <div className="d-flex">
                          <button className={"btn-icon col-1"}
                                  onClick={() => handleRemoveQuestionClick(questionIndex)}>
                            <FontAwesomeIcon icon={faMinusCircle}
                                             size={"xl"} color={"#af2525"}
                                             type={"button"}></FontAwesomeIcon>
                          </button>
                          <div className="form-floating col-11">
                            <textarea
                                disabled={isDownloading()}
                                className="form-control"
                                id={`Q${questionIndex}textArea`}
                                placeholder="Question"
                                onChange={(event) => handleQuizChange({
                                  text: event.target.value
                                } as Partial<Question>, questionIndex)}
                                value={question.text}/>
                            <label
                                htmlFor={`Q${questionIndex}textArea`}>{`Q${questionIndex + 1}`}</label>

                            <label className="image-upload">
                              <input className={"image-upload"}
                                     type="file"
                                     accept="image/*"
                                     ref={(el) => imageInputRefs.current[questionIndex + 1] = el}
                                     onChange={(e) => handleImageChange(e, questionIndex)}/>
                            </label>
                            <button className="inside-button"
                                    onClick={() => handleUploadImage(questionIndex + 1)}>
                              <FontAwesomeIcon icon={faImage} color={"#1cb9c8"}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={"options"}>
                        <span>Options</span>
                        <button disabled={(question?.options.length > 3) || isDownloading()}
                                onClick={() => handleAddOption(questionIndex)}
                                className={"btn-icon"}>
                          <FontAwesomeIcon
                              icon={faCirclePlus}
                              type={"button"}
                              size={"xl"} color={"#157536"}
                          />
                        </button>
                      </div>

                      <div className="col options-container">
                        {question?.options.map((option, optionIndex) => {
                              return <div
                                  key={optionIndex}
                                  className={"row"}>
                                <div className="col-1 center">
                                  <button
                                      disabled={isDownloading()}
                                      className={"btn-icon"}
                                      onClick={() => handleRemoveOption(questionIndex, option.id)}
                                  >
                                    <FontAwesomeIcon
                                        icon={faMinusCircle} color={"#af2525"}
                                        type={"button"}
                                        size={"xl"}/>
                                  </button>

                                </div>
                                <div className="col-9">
                                  <input type="text"
                                         disabled={isDownloading()}
                                         className="form-control"
                                         id={`Q${questionIndex + 1}-O-${optionIndex + 1}`}
                                         value={option.value}
                                         onChange={(event) => handleOptionChange(questionIndex, option.id, event)}
                                         placeholder={`Option ${optionIndex + 1}`}/>
                                </div>
                                <div className="col-1 center">
                                  <input className="form-check-input" type="radio"
                                         disabled={isDownloading()}
                                         name={`Q${questionIndex}-option-${optionIndex}`}
                                         checked={option.correct}
                                         onChange={() => handleCorrectOptionChange(questionIndex, option.id)}
                                         id={`Q${questionIndex + 1}-O${optionIndex + 1}`}
                                         aria-label="..."/>
                                </div>
                              </div>
                            }
                        )}
                      </div>
                    </div>
                );
              })
          ) : (
              <div>No questions available.</div> // Fallback if there are no questions
          )}
        </div>
      </div>
    }
)