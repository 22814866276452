export const logToNetlify = async (eventName: string, eventData: any, userUid?: string, ) => {
  try {
    const response = await fetch('/log-event', {
      method: 'POST',
      body: JSON.stringify(eventData),
      headers: {
        'Content-Type': 'application/json',
        'X-Event-Name': eventName,
        'X-User-Uid': userUid ?? 'not_authenticated'
      },
    });
    await response.text();
  } catch (error) {
    console.error('Error logging event:', error);
  }
};