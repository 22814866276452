export interface Voice {
  name: string;
  gender: "MALE" | "FEMALE"; // Assuming these are the only two options
  voiceName: string;
  languageCode: string;
  src: string;
}

// Define the interface for the voices map
export interface VoicesMap {
  [language: string]: Voice[]; // The key is a string (language), and the value is an array of Voice objects
}

export const voicesMap: VoicesMap = {
  "english": [
    {
      name: "Bruno",
      gender: "MALE",
      languageCode: "en-US",
      voiceName: "en-US-Wavenet-A",
      src: "bruno_en_wavenet_a_male.wav"
    },
    {
      name: "Emma",
      gender: "FEMALE",
      languageCode: "en-US",
      voiceName: "en-US-Neural2-H",
      src: "emma_en_neutral2h_female.wav"
    },
    {
      name: "Isabella",
      gender: "FEMALE",
      languageCode: "en-US",
      voiceName: "en-US-wavenet-G",
      src: "isabella_en_wavenet_g_female.wav"
    },
    {
      name: "James",
      languageCode: "en-US",
      gender: "MALE",
      voiceName: "en-US-Polyglot-1",
      src: "james_en_polygot_1_male.wav"
    },
    {
      name: "Liam",
      gender: "MALE",
      languageCode: "en-US",
      voiceName: "en-US-Neural2-A",
      src: "liam_en_neutral_2_A_male.wav"
    },
    {
      name: "Sophia",
      gender: "FEMALE",
      languageCode: "en-US",
      voiceName: "en-US-News-L",
      src: "sophia_en_new_L_female.wav"
    },
  ],
  "french": [
    {
      name: "Clara",
      gender: "FEMALE",
      languageCode: "fr-FR",
      voiceName: "fr-FR-Wavenet-A",
      src: "clara_fr_wavenet_a_female.wav"
    },
    {
      name: "Hugo",
      gender: "MALE",
      languageCode: "fr-FR",
      voiceName: "fr-FR-Polyglot-1",
      src: "hugo_fr_polygot_1_male.wav"
    },

  ],
  "spanish": [
    {
      name: "Lucia",
      gender: "FEMALE",
      languageCode: "es-ES",
      voiceName: "es-ES-Wavenet-C",
      src: "lucia_es_wavenet_c_female.wav"
    },
    {
      name: "Mateo",
      gender: "MALE",
      languageCode: "es-ES",
      voiceName: "es-ES-Wavenet-B",
      src: "mateo_es_wavenet_b_male.wav"
    },

  ],
  "german": [
    {
      name: "Anna",
      gender: "FEMALE",
      languageCode: "de-DE",
      voiceName: "de-DE-Wavenet-C",
      src: "anna_de_wavenet_c_female.wav"
    },
    {
      name: "Felix",
      languageCode: "de-DE",
      gender: "MALE",
      voiceName: "de-DE-Wavenet-C",
      src: "felix_de_wavenet_b_male.wav"
    },
  ]
};
