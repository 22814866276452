interface Timepoint {
  timeSeconds: number;

  [key: string]: any;
}

export interface TimepointWithDuration extends Timepoint {
  duration?: number;
}

export const toVoices = (voices: any[]) => {
  console.log("voices from tts" ,voices)
}

export const toTtsReponseWithDurations = (ttsResponse: any) => {
  console.log(ttsResponse)
  return {
    audioContent: ttsResponse.audioContent,
    audioDuration: ttsResponse.timepoints[ttsResponse.timepoints.length - 1].timeSeconds,
    timepoints: toTimepointsDurations(ttsResponse.timepoints),
    audioConfig: ttsResponse.audioConfig
  }
}

export const toTimepointsDurations = (timepoints: Timepoint[]): TimepointWithDuration[] => {
  return timepoints.map((timepoint, index) => {
    if (index === 0) return {...timepoint, duration: timepoint.timeSeconds};
    return {
      ...timepoint,
      duration: timepoint.timeSeconds - timepoints[index - 1].timeSeconds,
    };
  });
};

const blobToArrayBuffer = (blob: Blob) => {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  });
};

export const base64ToFile = async (base64: string, contentType: string = 'audio/mpeg', sliceSize: number = 512): Promise<ArrayBuffer | null> => {
  try {
    const byteCharacters = atob(base64); // Decode base64 string
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType}); // Create blob
    // downloadFile(URL.createObjectURL(blob), 'test.mp3')
    return await blobToArrayBuffer(blob);
  } catch (error) {
    return null
  }

}
