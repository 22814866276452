export const topupNewUserCredits = async (uid: string): Promise<void> => {
    try {
        const response = await fetch(`/.netlify/functions/topup-new-user/topup-new-user?uid=${uid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        console.log("response", response)
        if (!response.ok) {
            console.log('Network response was not ok');
            throw new Error("Unexpected problem");
        }
    } catch (error) {
        console.error('Internal Error', error);
        throw new Error("Unexpected problem");
    }
}
