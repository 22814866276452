import {useState, useEffect} from 'react';
import {UserSubscriptionPlan} from "../../types";
import useAuthState from "./auth-state-hook";
import {getUserSubscriptionPlanById} from "../../services/userService";

const useUserSubscriptionState = () => {
  const {currentUser} = useAuthState()

  const [userSubscription, setUserSubscription] = useState<UserSubscriptionPlan | null>(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  useEffect(() => {
    setSubscriptionLoading(false)
    if (currentUser) {
      getUserSubscriptionPlanById(currentUser.uid).then(userSubscription => {
        console.log("userSub:", userSubscription)
        setUserSubscription(userSubscription)
        setSubscriptionLoading(false)
      })
    }
  }, [currentUser]);


  return {currentUser, userSubscription, subscriptionLoading};
};

export default useUserSubscriptionState;